import { Timestamp } from "rxjs/internal/operators/timestamp";



export class glossaire {

  public id: number;
  public nom: string;
  public definition: string;
  public created_at: any;
  public updated_at: any;
  

  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {
      if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
          this.errorMessage = "Nom manquant"; return false;
      }

      if(this.definition === undefined || this.definition === null || this.definition.trim() === ""){
        this.errorMessage = "Définition manquante"; return false;
    }

      return true;
  }//end canSave


}
