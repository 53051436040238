/**
 * Created by utilisateur on 31/03/2017.
 */

/**
 * Created by utilisateur on 29/03/2017.
 */


import { Injectable } from '@angular/core';




@Injectable()
export class LocalStorageService {


  private connectedAppUserKey: string = "M_MAC_OFFE_APP_USER";
  private currentFichePssn: string = "M_MAC_OFFE_APP_FS_PSSN";
  private currentFichePe: string = "M_MAC_OFFE_APP_FS_PE";
  private currentFichePffg: string = "M_MAC_OFFE_APP_FS_PFFG";
  private currentAppSelectedStat: string = "M_MAC_OFFE_APP_SELECTED_STAT";
  private currentAppGlobalStatParam: string = "M_MAC_OFFE_APP_GLOBAL_STAT_PARAM";

  private currentAppPssnSearchParam: string = "M_MAC_OFFE_APP_PSSN_SEARCH_PARAM";
  private currentAppPeSearchParam: string = "M_MAC_OFFE_APP_PE_SEARCH_PARAM";
  private currentAppPffgSearchParam: string = "M_MAC_OFFE_APP_PFFG_SEARCH_PARAM";



  constructor() {}

    //enregistre un utilisateur connecte
    public saveUser (obj: any): void{
    this.saveToSession(this.connectedAppUserKey, obj);
  };
    //retourne un user
    public getUser (): any {
    return this.readFromSession(this.connectedAppUserKey);
  };

  //enregistre une fiche fs_pssn
  public saveFicheFsPssn (obj: any): void{
    this.saveToSession(this.currentFichePssn, obj);
  };
  //retourne une fiche fs_pssn
  public getFicheFsPssn (): any {
    return this.readFromSession(this.currentFichePssn);
  };


  //enregistre une fiche fs_pe
  public saveFicheFsPe (obj: any): void{
    this.saveToSession(this.currentFichePe, obj);
  };
  //retourne une fiche fs_pe
  public getFicheFsPe (): any {
    return this.readFromSession(this.currentFichePe);
  };


  //enregistre une fiche fs_pffg
  public saveFicheFsPffg (obj: any): void{
    this.saveToSession(this.currentFichePffg, obj);
  };

  //retourne une fiche fs_pe
  public getFicheFsPffg (): any {
    return this.readFromSession(this.currentFichePffg);
  };


  //enregistre une fiche stat
  public saveSelectedStat (obj: any): void{
    this.saveToSession(this.currentAppSelectedStat, obj);
  };

  //retourne une fiche stat
  public getSelectedStat (): any {
    return this.readFromSession(this.currentAppSelectedStat);
  };

  //enregistre un param de stat global
  public saveSelectedGlobalStatParam (obj: any): void{
    this.saveToSession(this.currentAppGlobalStatParam, obj);
  };//end saveSelectedGlobalStatParam

  //retourne un param de stat global
  public getSelectedGlobalStatParam (): any {
    return this.readFromSession(this.currentAppGlobalStatParam);
  };//end getSelectedGlobalStatParam


  //enregistre un critere de recherche fiche pssn
  public saveSelectedPssnSearchParam (obj: any): void{
    this.saveToSession(this.currentAppPssnSearchParam, obj);
  };//end saveSelectedPssnSearchParam

  //retourne un critere de recherche fiche pssn
  public getSelectedPssnSearchParam (): any {
    return this.readFromSession(this.currentAppPssnSearchParam);
  };//end getSelectedPssnSearchParam

    //enregistre un critere de recherche fiche pe
    public saveSelectedPeSearchParam (obj: any): void{
      this.saveToSession(this.currentAppPeSearchParam, obj);
    };//end saveSelectedPssnSearchParam

    //retourne un critere de recherche fiche pe
    public getSelectedPeSearchParam (): any {
      return this.readFromSession(this.currentAppPeSearchParam);
    };//end getSelectedPeSearchParam

      //enregistre un critere de recherche fiche pffg
  public saveSelectedPffgSearchParam (obj: any): void{
    this.saveToSession(this.currentAppPffgSearchParam, obj);
  };//end saveSelectedPffgSearchParam

  //retourne un critere de recherche fiche pffg
  public getSelectedPffgSearchParam (): any {
    return this.readFromSession(this.currentAppPffgSearchParam);
  };//end getSelectedPffgSearchParam






  private saveToSession (key: string, value: any): void{
    var stringified = JSON.stringify(value);
    var jsonValue = btoa(stringified);
    localStorage.setItem(key, jsonValue);
  };

  private readFromSession (key: any): any{
    var result = null;
    try{
      var json = localStorage.getItem(key);
      var result = JSON.parse(atob(json));
    }catch(e){
      result = null;
    }
    return result;
  };

}

