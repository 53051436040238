import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

@Injectable()
export class AppStorageService {

  private connectedUserKey: string = "SIDOFFENG_CONNECTED_USER";

  constructor(
      @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  //get connected user
  public setConnectedUser(user: any): void {
      this.storage.set(this.connectedUserKey, user);
  }
  public getConnectedUser(): any {
  let emptyObj = {};
      return this.storage.get(this.connectedUserKey);
  }






}
