import { Component, Input, Output, EventEmitter, OnInit, OnChanges} from '@angular/core';
import * as moment from 'moment';
import {ConstantsService} from "../../../shared/services/general/constants.service";


@Component({
  selector: 'public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.css']
})

export class PublicHeaderComponent implements  OnInit{


  public constructor(
  ) {

  }

  ngOnInit(): void{
  }




}
