import {Injectable} from "@angular/core";


@Injectable()
export class ConstantsService {

    static instance: ConstantsService;

    constructor() {
        ConstantsService.instance = this;
    }


    //liste de mois
  MONTH_LIST = [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aôut","Septembre","Octobre","Novembre","Décembre" ];

    //LISTE DES THEMATIQUES
  DOMAINE_LISTE = [
    //{ id: 1, designation: ""  }
  ];


  THEMATIQUE_LISTE = [
    {

    }
  ];



  //libelles et valeurs utilisées sur les formulaires dedition et decreation de fiches

  //fiche protection sociale et solidarite nationale
  static REFERENCE_DOMAINE_PSSN: number = 5;
  static LABEL_TYPE_SECOURS: string = "type_secours"; static DESIGNATION_TYPE_SECOURS: string = "Secours nationaux";
  static LABEL_FILET_SOCIAL: string = "filet_social"; static DESIGNATION_FILET_SOCIAL: string = "Filets sociaux";
  static LABEL_CAS_SOCIAUX: string = "cas_sociaux"; static DESIGNATION_CAS_SOCIAUX: string = "Insertion socio-professionnelle des jeunes cas sociaux";
  static LABEL_APPUI_GENERAL: string = "appui_general"; static DESIGNATION_APPUI_GENERAL: string = "Personne de troisième âge (PTA)";

  //fiche protection de l'enfant
  static REFERENCE_DOMAINE_PE: number = 3;
  //static LABEL_TYPE_SECOURS: string = "type_secours"; static DESIGNATION_TYPE_SECOURS: string = "Secours nationaux";

  //fiche protection de la famille, de la femme et du genre
  static REFERENCE_DOMAINE_PFFG: number = 4;
  static LABEL_CAPE_FAMILLE_ACCUEIL: string = "cape_famille_accueil";    static LABEL_NOT_CAPE_FAMILLE_ACCUEIL: string = "not_cape_famille_accueil";
  static DESIGNATION_ACCOMPAGNEMENT_CAPE: string = "Accompagnement CAPE";   static DESIGNATION_FAMILLES: string = "Familles hôtes / familles d'accueil";


  //lib disabled



  //PSSN area

  static LABEL_GROSSESSES_PRECOCES_CHARGE: string = "Nombre de grossesses précoces pris en charge";
  static LABEL_GROSSESSES_PRECOCES_ENREGISTREES: string = "Nombre total de grossesses précoces enregistrées dans les structures de prise en charge";
  static LABEL_INDICATEURS_GROSSESSES_DISABLED: string [] = [ ConstantsService.LABEL_GROSSESSES_PRECOCES_CHARGE, ConstantsService.LABEL_GROSSESSES_PRECOCES_ENREGISTREES ];

  static LABEL_MONTANT_SECOURS_OCTROYE: string = "Montant total octroyé pour le secours (en millier de francs CFA)";
  static LABEL_MONTANT_SECOURS_SOLLICITE: string = "Montant sollicité auprès des CPS, SSS et autres ONG partenaires (en millier de francs CFA)";


  //ndicateurs pssn avec des niveaux de desagregation supplementaire
  static DESIGNATION_INDICATEUR_PSSN_1: string = "Nombre total de demandes de secours nationaux satisfaites";
  static DESIGNATION_INDICATEUR_PSSN_2: string = " Nombre total de demandes de secours nationaux reçues par les CPS, SSS, ONG, Police Républicaine et autres structures partenaires";


  static DESIGNATION_INDICATEUR_PSSN_3: string = "Nombre de ménages bénéficiaires de filets sociaux (transfert monétaire, transfert en nature, prise en charge sanitaire, prise en charge scolaire…)";
  static DESIGNATION_INDICATEUR_PSSN_4: string = "Nombre de ménage pauvres identifiés";
  static DESIGNATION_INDICATEUR_PSSN_5: string = "Somme des montants octroyés pour les filets sociaux (en millier de francs CFA)";
  static LABEL_INDICATEURS_FILETS_SOCIAUX_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_3, ConstantsService.DESIGNATION_INDICATEUR_PSSN_4,  ConstantsService.DESIGNATION_INDICATEUR_PSSN_5  ];


  static LABEL_INDICATEURS_PSSN_ALL_DISABLED: string [] = [ ConstantsService.LABEL_MONTANT_SECOURS_OCTROYE, ConstantsService.LABEL_MONTANT_SECOURS_SOLLICITE, ConstantsService.DESIGNATION_INDICATEUR_PSSN_3, ConstantsService.DESIGNATION_INDICATEUR_PSSN_4, ConstantsService.DESIGNATION_INDICATEUR_PSSN_5 ];


  static DESIGNATION_INDICATEUR_PSSN_6: string = "Nombre de jeunes cas sociaux appuyés (appuiinstallation, formation dans les cours éducatifs)";
  static DESIGNATION_INDICATEUR_PSSN_7: string = "Nombre total de jeunes cas sociaux identifiés par les CPS, ONG partenaire";


  static DESIGNATION_INDICATEUR_PSSN_8: string = "Nombre total de demandes d'appui des PTA satisfaites";
  static DESIGNATION_INDICATEUR_PSSN_9: string = "Nombre total d'appuis sollicités par les PTA auprès des CPS, SSS et autres structures partenaires,";


  static DESIGNATION_INDICATEUR_PSSN_10: string = "Nombre de jeunes cas sociaux appuyés (appui-installation, formation dans les cours éducatifs)";
  static DESIGNATION_INDICATEUR_PSSN_11: string = "Nombre total de jeunes cas sociaux identifiés par les CPS, ONG et Structures Partenaires";
  static LABEL_INDICATEURS_SOCIO_PRO_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_10,  ConstantsService.DESIGNATION_INDICATEUR_PSSN_11 ];

  static DESIGNATION_INDICATEUR_PSSN_12: string = " Nombre total de demandes d’appui pour  naissances multiples satisfaites";
  static DESIGNATION_INDICATEUR_PSSN_13: string = "Nombre total de demandes d’appui pour naissances multiples enregistrées par les CPS, SSS, ONG, Police Républicaine et autres structures partenaires";
  static LABEL_INDICATEURS_NAISSANCES_MULTIPLES_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_12, ConstantsService.DESIGNATION_INDICATEUR_PSSN_13  ];

  static DESIGNATION_INDICATEUR_PSSN_14: string = "Nombre total de demandes d'appui des PTA satisfaites";
  static DESIGNATION_INDICATEUR_PSSN_15: string = "Nombre total d'appuis sollicités par les PTA auprès des CPS, SSS, ONG et Structures Partenaires";
  static LABEL_INDICATEURS_APPUI_PTA_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_14, ConstantsService.DESIGNATION_INDICATEUR_PSSN_15  ];

  static DESIGNATION_INDICATEUR_PSSN_16: string = "Nombre d’adultes victimes de traite, réintégrés dans leur famille ou dans la communauté";
  static DESIGNATION_INDICATEUR_PSSN_17: string = " Nombre d’adultes victimes de traite recensés par les CPS, SSS, Structures d'accueil, ONG et Structures Partenaires";
  static DESIGNATION_INDICATEUR_PSSN_18: string = "Nombre d’adultes victimes de traite nationale";
  static DESIGNATION_INDICATEUR_PSSN_19: string = "Nombre d’adultes victimes de traite transfrontalière";
  static DESIGNATION_INDICATEUR_PSSN_20: string = "Nombre de consultations sociales faites par les CPS";
  static LABEL_INDICATEURS_TRAITE_ADULTES_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_16, ConstantsService.DESIGNATION_INDICATEUR_PSSN_17, ConstantsService.DESIGNATION_INDICATEUR_PSSN_18, ConstantsService.DESIGNATION_INDICATEUR_PSSN_19 ];

  static LABEL_INDICATEURS_CONSULTATION_CPS_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PSSN_20  ];


  //PE area
  static DESIGNATION_INDICATEUR_PE_1: string = "Nombre de CAPE identifiés";
  static DESIGNATION_INDICATEUR_PE_2: string = "Nombre de CAPE autorisés";

  static DESIGNATION_INDICATEUR_PE_3: string = " Nombre de filles  victimes d’excision recensées par les CPS, Formations sanitaires, ONG et autres Structures Partenaires";
  static DESIGNATION_INDICATEUR_PE_4: string = " Nombre de filles  victimes d’excision prises en charge par les CPS, Formations sanitaires, ONG et autres Structures Partenaires";
  static DESIGNATION_INDICATEUR_PE_5: string = "Nombre de cas d’excision référés vers les structures de répression (Sécurité, Justice)";
  static DESIGNATION_INDICATEUR_PE_6: string = "Nombre de cas d’excision ayant fait l’objet d’une décision de justice";

  static DESIGNATION_INDICATEUR_PE_7: string = " Nombre de cas de  grossesses précoces pris en charge ";
  static DESIGNATION_INDICATEUR_PE_8: string = " Nombre total de cas de grossesses précoces enregistrées dans les structures de prise en charge";
  static DESIGNATION_INDICATEUR_PE_9: string = " Nombre de cas de mariages précoces pris en charge";
  static DESIGNATION_INDICATEUR_PE_10: string = " Nombre total de cas de mariages précoces enregistrés";
  static DESIGNATION_INDICATEUR_PE_11: string = " Nombre total de cas d’enlèvement et de séquestration survenus";
  static DESIGNATION_INDICATEUR_PE_12: string = " Nombre de cas de violences sexuelles reçus";
  static DESIGNATION_INDICATEUR_PE_13: string = " Nombre de cas de violences économiques reçus";
  static DESIGNATION_INDICATEUR_PE_14: string = " Nombre de cas de violences physiques reçus";
  static DESIGNATION_INDICATEUR_PE_15: string = " Nombre de cas de violences psychologiques ou morales reçus";
  static DESIGNATION_INDICATEUR_PE_16: string = " Nombre de cas de violences patrimoniales ou culturelles reçus";

  static LABEL_INDICATEURS_MUTILATIONS_GENITALES_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PE_3,  ConstantsService.DESIGNATION_INDICATEUR_PE_4,
    ConstantsService.DESIGNATION_INDICATEUR_PE_5,  ConstantsService.DESIGNATION_INDICATEUR_PE_6, ConstantsService.DESIGNATION_INDICATEUR_PE_7, ConstantsService.DESIGNATION_INDICATEUR_PE_8 ,
    ConstantsService.DESIGNATION_INDICATEUR_PE_9,   ConstantsService.DESIGNATION_INDICATEUR_PE_10,   ConstantsService.DESIGNATION_INDICATEUR_PE_11,
    ConstantsService.DESIGNATION_INDICATEUR_PE_12,  ConstantsService.DESIGNATION_INDICATEUR_PE_13,  ConstantsService.DESIGNATION_INDICATEUR_PE_14,
    ConstantsService.DESIGNATION_INDICATEUR_PE_15,  ConstantsService.DESIGNATION_INDICATEUR_PE_16,   ]

  static LABEL_INDICATEURS_PE_ALL_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PE_1, ConstantsService.DESIGNATION_INDICATEUR_PE_2,
  ];

  static LABEL_INDICATEURS_PE_GROSSESSE_AGE_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PE_7, ConstantsService.DESIGNATION_INDICATEUR_PE_8,
    ConstantsService.DESIGNATION_INDICATEUR_PE_9, ConstantsService.DESIGNATION_INDICATEUR_PE_10,
  ];

  static LABEL_INDICATEURS_PE_GROSSESSE_H_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PE_7, ConstantsService.DESIGNATION_INDICATEUR_PE_8,
  ];


  //PFFG area


  static LABEL_CAPE_ENREGISTRES: string = "Nombre de CAPE enregistrés";
  static LABEL_CAPE_SUIVIS: string = "Nombre de CAPE suivis";
  static LABEL_GROUPEMENTS_FEMININS_APPUYES: string = "Nombre de groupements féminins/mixte appuyés";
  static LABEL_GROUPEMENTS_FEMININS_ENREGISTRES: string = "Nombre de groupements féminins/mixte enregistrés";
  static LABEL_INDICATEURS_PFFG_ALL_DISABLED: string [] = [ConstantsService.LABEL_CAPE_ENREGISTRES, ConstantsService.LABEL_CAPE_SUIVIS, ConstantsService.LABEL_GROUPEMENTS_FEMININS_APPUYES, ConstantsService.LABEL_GROUPEMENTS_FEMININS_ENREGISTRES];

  static DESIGNATION_INDICATEUR_PFFG_1: string = " Nombre de cas relatifs aux violences basées sur le genre pris en charge";
  static DESIGNATION_INDICATEUR_PFFG_2: string = " Nombre de cas relatifs aux violences basées sur le genre référés aux OPJ ou au tribunal";
  static DESIGNATION_INDICATEUR_PFFG_3: string = " Nombre de cas relatifs aux violences basées sur le genre référés dans les formations sanitaires";
  static DESIGNATION_INDICATEUR_PFFG_4: string = " Nombre de cas de violences physiques reçus";
  static DESIGNATION_INDICATEUR_PFFG_5: string = " Nombre de cas de violences sexuelles reçus";
  static DESIGNATION_INDICATEUR_PFFG_6: string = " Nombre de cas de violences économiques reçus";
  static DESIGNATION_INDICATEUR_PFFG_7: string = " Nombre de cas de violences psychologiques ou morales reçus";
  static DESIGNATION_INDICATEUR_PFFG_8: string = " Nombre de cas de violences patrimoniales ou culturelles reçus";
  static DESIGNATION_INDICATEUR_PFFG_9: string = " Nombre de cas relatifs aux violences basées sur le genre reçus dans les structures de prise en charge";
  static DESIGNATION_INDICATEUR_PFFG_10: string = " Nombre total de cas d’enlèvement et de séquestration survenus";
  static DESIGNATION_INDICATEUR_PFFG_11: string = " Nombre total de cas d’incestes recensés";
  static DESIGNATION_INDICATEUR_PFFG_12: string = "﻿Nombre total de cas de violences liées au lévirat recensés";
  static DESIGNATION_INDICATEUR_PFFG_13: string = "﻿Nombre total de cas de harcèlement recensés";

  static DESIGNATION_INDICATEUR_PFFG_14: string = "﻿Nombre de victimes d'excision recensées par les CPS, Formations sanitaires, ONG et autres Structures Partenaires";
  static DESIGNATION_INDICATEUR_PFFG_15: string = "﻿Nombre de victimes d'excision prises en charge par les CPS, Formations sanitaires, ONG et autres Structures Partenaires";
  static DESIGNATION_INDICATEUR_PFFG_16: string = "﻿Nombre de cas d'excision référés vers les structures de répression (Sécurité, Justice)";
  static DESIGNATION_INDICATEUR_PFFG_17: string = "﻿Nombre de cas d'excision ayant fait l’objet d’une décision de justice";

  static LABEL_INDICATEURS_PFFG_VBG_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_1, ConstantsService.DESIGNATION_INDICATEUR_PFFG_2,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_3, ConstantsService.DESIGNATION_INDICATEUR_PFFG_4, ConstantsService.DESIGNATION_INDICATEUR_PFFG_5, ConstantsService.DESIGNATION_INDICATEUR_PFFG_6, ConstantsService.DESIGNATION_INDICATEUR_PFFG_7,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_8, ConstantsService.DESIGNATION_INDICATEUR_PFFG_9, ConstantsService.DESIGNATION_INDICATEUR_PFFG_10, ConstantsService.DESIGNATION_INDICATEUR_PFFG_11, ConstantsService.DESIGNATION_INDICATEUR_PFFG_12, ConstantsService.DESIGNATION_INDICATEUR_PFFG_13,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_14, ConstantsService.DESIGNATION_INDICATEUR_PFFG_15, ConstantsService.DESIGNATION_INDICATEUR_PFFG_16, ConstantsService.DESIGNATION_INDICATEUR_PFFG_17,
  ];

  static LABEL_INDICATEURS_PFFG_VBG_LEVIRAT_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_12,   ];

  static LABEL_INDICATEURS_PFFG_MUTILATIONS_GENITALES_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_14, ConstantsService.DESIGNATION_INDICATEUR_PFFG_15,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_16, ConstantsService.DESIGNATION_INDICATEUR_PFFG_17, ];

  static DESIGNATION_INDICATEUR_PFFG_18: string = "Nombre de séances de sensibilisations faites pour la promotion des valeurs familiales";
  static DESIGNATION_INDICATEUR_PFFG_19: string = "﻿ Nombre de séance de promotion dialogue parents- enfants sur les sujets de sexualité";
  static DESIGNATION_INDICATEUR_PFFG_20: string = "﻿Nombre de participants touchés par les séances de sensibilisation sur la promotion des valeurs familiales";
  static DESIGNATION_INDICATEUR_PFFG_21: string = "﻿Nombre de participants touchés par les séances de promotion dialogue parents-enfants sur les sujets de sexualité";
  static DESIGNATION_INDICATEUR_PFFG_22: string = "﻿Nombre de participants touchés par les séances de dialogue intergénérationnel";
  static DESIGNATION_INDICATEUR_PFFG_23: string = "﻿ Nombre de séances de dialogue intergénérationnel faites";
  static DESIGNATION_INDICATEUR_PFFG_24: string = "﻿ Nombre de ménages touchés par les plaidoyers dans le cadre des dialogues parents enfants";
  static DESIGNATION_INDICATEUR_PFFG_25: string = "﻿Nombre de jeunes et adolescents ayant adopté une méthode moderne de contraception";

  static LABEL_INDICATEURS_PFFG_PSSAJ_SEXE_HANDICAP_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_18, ConstantsService.DESIGNATION_INDICATEUR_PFFG_19,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_23, ConstantsService.DESIGNATION_INDICATEUR_PFFG_24, ];

  static LABEL_INDICATEURS_PFFG_PSSAJ_AGE_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_18, ConstantsService.DESIGNATION_INDICATEUR_PFFG_19,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_23,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_24,  ]; //ConstantsService.DESIGNATION_INDICATEUR_PFFG_20, ConstantsService.DESIGNATION_INDICATEUR_PFFG_22,   ConstantsService.DESIGNATION_INDICATEUR_PFFG_21, 

  static LABEL_INDICATEURS_PFFG_ADO_CONTRACEPTION_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_25 ];

  static DESIGNATION_INDICATEUR_PFFG_26: string = "﻿Nombre de femmes formées/encadrées au leadership féminins (postes de prise de décisions)";
  static LABEL_INDICATEURS_PFFG_FEMME_LEADER_H_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_26 ];

  static DESIGNATION_INDICATEUR_PFFG_27: string = "﻿Nombre de femmes en prisons enregistrées";
  static DESIGNATION_INDICATEUR_PFFG_28: string = "﻿Nombre de femmes en prison libérées";
  static DESIGNATION_INDICATEUR_PFFG_29: string = "﻿Nombre de femmes en prison sans jugement";
  static DESIGNATION_INDICATEUR_PFFG_30: string = "﻿Proportion de femmes en prison attendant un jugement ";

  static LABEL_INDICATEURS_PFFG_PRISON_H_DISABLED: string [] = [ ConstantsService.DESIGNATION_INDICATEUR_PFFG_27, ConstantsService.DESIGNATION_INDICATEUR_PFFG_28,
    ConstantsService.DESIGNATION_INDICATEUR_PFFG_29, ConstantsService.DESIGNATION_INDICATEUR_PFFG_30, ];

  static DESIGNATION_INDICATEUR_PFFG_31: string = "Nombre de participants touchés par les séances de promotion dialogue parents-enfants sur les sujets de sexualité";

  static LABEL_INDICATEURS_PFFG_PROMO_DIALOGUE_PARENT_ENFANT_DISABLED: string [] = [  ]; //ConstantsService.DESIGNATION_INDICATEUR_PFFG_31


  static STATUT_FICHE_ANNULATION: string = "ANNULATION";




}
