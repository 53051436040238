import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SecuService} from "../../../shared/services/secu/secu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {LibraryService} from "../../../shared/services/app/library.service";
import {ParamService} from "../../../shared/services/param/param.service";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../shared/services/general/utils.service";
import {ConfirmationService} from "primeng/api";
import {utilisateur} from "../../../shared/model/utilisateur";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {LocalStorageService} from "../../../shared/services/app/localstorage.service";

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  newUser : utilisateur = new utilisateur();

  constructor(private router: Router, private paramService: ParamService, private libraryService: LibraryService,
              private spinner: NgxSpinnerService, private authService: AuthService,
              private toastr: ToastrService , private utils: UtilsService,
              private confirmationService: ConfirmationService, private localStorage: LocalStorageService ) { }

  items: any[] = [ "hjsdghsd", "gjshgfjh", "sjhdghgh", "jhsdghghg", "jhfghjghgh", "hjdghghgh", "sdgfghjfg" ];

  ngOnInit() {
    this.newUser.login = "";
    this.newUser.mot_passe = "";
  }



}
