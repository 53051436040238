import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {NgbModalModule, NgbModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';

import { ComponentsModule } from './components/components.module';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {ToastrModule} from "ngx-toastr";
import {NgxSpinnerModule} from "ngx-spinner";
import {AuthService} from "./shared/services/auth/auth.service";
import {AppStorageService} from "./shared/services/general/appstorage.service";
import {StorageServiceModule, WebStorageService} from 'angular-webstorage-service';
import {ParamService} from "./shared/services/param/param.service";
import {ConfirmationService} from "primeng/api";
import {ConstantsService} from "./shared/services/general/constants.service";
//import { FormatMoneyPipe } from './shared/pipes/format-money/format-money.pipe';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {AppService} from "./shared/services/app/app.service";
import {LoginComponent} from "./components/auth/login/login.component";
import {UtilsService} from "./shared/services/general/utils.service";
import {SecuService} from "./shared/services/secu/secu.service";
import {OperationService} from "./shared/services/operation/operation.service";
import {PublicDashboardComponent} from "./components/main/home/public/publicdashboard.component";
import {
  AccordionModule,
  ButtonModule,
  ChartModule, ConfirmDialogModule, FileUploadModule, GrowlModule,
  InputTextModule,
  MessagesModule,
  PaginatorModule
} from "primeng/primeng";
import {LibraryService} from "./shared/services/app/library.service";
import {ResetComponent} from "./components/auth/reset/reset.component";
import {LocalStorageService} from "./shared/services/app/localstorage.service";
import {ReportService} from "./shared/services/report/report.service";
import {CarouselModule} from "ngx-owl-carousel-o";
import {DialogModule} from "primeng/dialog";
// import {PublicGlobalStatsPssnComponent} from "./components/public/globalstats/pssn/publicglobalstats_pssn.component";
// import {ItDatePickerComponent} from "./components/utils/it-datepicker/it.datepicker.component";
import {CalendarModule} from "primeng/calendar";
import {PublicLayoutModule} from "./layouts/public-layout/public-layout.module";
import {PublicLayoutComponent} from "./layouts/public-layout/public-layout.component";
// import {ItBooleanPipe} from "./shared/pipes/it-boolean/it-boolean.pipe";
// import {ItPaginatorPipe} from "./shared/pipes/it-paginator/it-paginator.pipe";
// import {LimitToPipe} from "./shared/pipes/limit-to/limit-to.pipe";
// import {FormatToMonthPipe} from "./shared/pipes/format-to-month/format-to-month.pipe";
// import {FormatStatusPipe} from "./shared/pipes/format-status/format-status.pipe";
import {TableModule} from "primeng/table";
import {ChangePasswordComponent} from "./components/auth/change/change.password.component";
import {TestComponent} from "./components/auth/test/test.component";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent, PublicLayoutComponent,
    LoginComponent, PublicDashboardComponent, ResetComponent, ChangePasswordComponent, TestComponent,


  ],
  imports: [
    BrowserAnimationsModule,

    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    NgxSpinnerModule, StorageServiceModule,
    NgbModalModule.forRoot(), NgbTabsetModule.forRoot(),

    CarouselModule,
    InputTextModule, ButtonModule, CalendarModule, PaginatorModule, ChartModule, MessagesModule, DialogModule, ConfirmDialogModule,
    GrowlModule, FileUploadModule, TableModule, AccordionModule
  ],
  providers: [ UtilsService, AuthService, AppStorageService, ParamService,  ConfirmationService,
    ConstantsService,  AppService, SecuService, OperationService, LibraryService, LocalStorageService, ReportService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
