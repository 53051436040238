import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ParamService} from "../../../../shared/services/param/param.service";
import {UtilsService} from "../../../../shared/services/general/utils.service";
import {commentaire} from "../../../../shared/model/commentaire";
import {SecuService} from "../../../../shared/services/secu/secu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {LocalStorageService} from "../../../../shared/services/app/localstorage.service";

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publicdashboard',
  templateUrl: './publicdashboard.component.html',
  styleUrls: ['./publicdashboard.component.css']
})
export class PublicDashboardComponent implements OnInit {

  constructor(private router: Router, private paramService: ParamService, private secuService: SecuService,
              private localStorage: LocalStorageService,
              private toastr: ToastrService , private utils: UtilsService, private spinner: NgxSpinnerService,
              private sanitizer: DomSanitizer)
  {
  }

  displayVisualisationScreen: boolean = false;
  modal_form : any = {};
  linkVisualisation : string = "" ;
  iframeSrc : any;


  displayStatsGlobal: boolean =  false;

  customOptionsPartners: any = {  loop: true,   mouseDrag: true,  touchDrag: false,  pullDrag: false, autoplay: true,  autoplayTimeout: 5000, dots: false, navSpeed: 700,  navText: ['', ''], responsive: {    0: {  items: 1  },  400: {    items: 2   },  740: {   items: 3  },  940: {   items: 4    }  }, nav: true };

  customOptionsSlideShow: any = {  loop: true,  mouseDrag: true,  touchDrag: false, pullDrag: false,  autoplay: true,  autoplayTimeout: 5000,  dots: false,    navSpeed: 700,   navText: ['', ''], responsive: {   0: {  items: 1  }   },  nav: true  };

  comment: commentaire = new commentaire();

  pssnItems: any[] = [
    { id: 1, name: "Assistance aux familles", items: [ {name: "Secours Nationaux", code: "sec_nat"}, {name: "Secours sinistrés communautaires", code: "sec_com"}, {name: "Filets sociaux", code: "fil_sec"},
        {name: "Insertion socio-professionnelle des jeunes cas sociaux", code: "ins_soc"}, {name: "Naissances multiples", code: "nais_mult"}, {name: "Consultations sociales", code: "cons_soc"}, ] },

    { id: 2, name: "Traite des adultes", items: [ {name: "Traite des adultes", code: "trai_adu"},  ] },

    { id: 3, name: "Personnes du troisième âge (PTA)", items: [ {name: "Personnes du troisième âge (PTA)", code: "pta"},  ]  }
  ];

  peItems: any[] = [
    { id: 1, name: "Enfants en situation difficile", items: [ {name: "Enfants dans/ de la rue", code: "enf_rue"}, {name: "Enfants face à la justice", code: "enf_just"}, {name: "Orphelins et enfants vulnérables", code: "oev"},
     ] },

    { id: 2, name: "Enfants en milieu carcéral", items: [ {name: "Enfants vivant en prison", code: "enf_prison"},  ] },

    { id: 3, name: "Mobilité et travail des enfants", items: [ {name: "Traite des enfants", code: "trai_enf"}, {name: "Travail des enfants", code: "tra_enf"}, {name: "Migration interne et migration externe des enfants et des jeunes", code: "mig_enf"},  ]  },

    { id: 4, name: "CAPE et Famille hôtes", items: [ {name: "Accompagnement et suivi des CAPE", code: "accom_suivi_cape"}, {name: "Familles hôtes / familles d'accueil", code: "fam_hot_acc"}, {name: "Mutilations génitales féminines", code: "mut_gen_fem"},  {name: "Grossesses / mariages précoces", code: "mut_gen_fem"},  ]  }
  ];

  pffgItems: any[] = [
    { id: 1, name: "Violences", items: [ {name: "Violences basées sur le genre", code: "vi_ge"}, {name: "Mutilations génitales féminines", code: "mut_gen_fem"}, ] },

    { id: 2, name: "Promotion des valeurs familiales", items: [ {name: "Promotion de la Santé Sexuelle, des Adolescents et des Jeunes", code: "pro_ssaj"},  ] },

    { id: 3, name: "Autonomisation des femmes", items: [ {name: "Autonomisation des femmes", code: "aut_fem"}  ]  },

    { id: 4, name: "Femmes en milieu carcéral", items: [ {name: "Femmes en prison", code: "fem_prison"}  ]  }
  ];


  ngOnInit() {
    

  }//end oninit

  //show login page
  showLoginPage(): void {
    this.router.navigate(["login"]);
  }//end showLoginPage

  //show stats
  goToWay(type: string): void {

    let paramSearch: any = { startDate: new Date(), endDate: new Date(), codeIndic: "", departement: "",
      commune: "", thematique: "", sous_thematique: "", statistique: ""    };

    this.localStorage.saveSelectedGlobalStatParam(null);

    switch(type){
      case "pssn":
        this.router.navigate(["/sidoffepublic/stats/global/pssn"]);
        break;

      case "pe":
        this.router.navigate(["/sidoffepublic/stats/global/pe"]);
        break;

      case "pffg":
        this.router.navigate(["/sidoffepublic/stats/global/pffg"]);

        break;
    }

  }//end showStatsGlobal




  //send comment
  sendComment(): void {
    //test value
    if(!this.comment.canSave()){
      this.toastr.error(this.comment.getErrorMessage(), "Commentaire", {positionClass: 'toast-top-center'});
      return;
    }
    this.spinner.show();
    this.secuService.createComment(this.comment).subscribe((result) => {
      this.spinner.hide();
      if(result.status === true){
        this.comment = new commentaire();
        this.toastr.success("Votre message a été envoyé avec succès", "Commentaire", {positionClass: 'toast-top-center'});
        return;
      }else{
        this.toastr.error(result.status, "Commentaire", {positionClass: 'toast-top-center'});
        return;
      }

    })
  }//end sendComment


  //select link
  selectLink(item: any, line: any){
    let object: any = { thematique: item, sous_thematique: line };
    this.localStorage.saveSelectedStat(object);
  }//end selectLink

  ///show glodssaire page
  showGlossaireSearch(){
    console.log("test");
    this.router.navigate(["/sidoffepublic/glossaire/recherche"]);
  }//end selectLink


  //show visualisation screen 
  showVisualisationScreen(code: string): void  {
    switch (code) {

      case "pssn":
        this.linkVisualisation = environment.lien_visualisation_pssn ;
        break;

        case "pe":
          this.linkVisualisation = environment.lien_visualisation_pe ;
          break;

          case "pffg":
            this.linkVisualisation = environment.lien_visualisation_pffg ;
            break;
    
      default:
        break;
    }
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.linkVisualisation);   
    this.displayVisualisationScreen = true;
  }//end showVisualisationScreen


   //close visualisation forscreenm
   closeVisualisationScreen(): void {
    this.displayVisualisationScreen = false;
  }//end closeEditForm





}
