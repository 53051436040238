import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {LoginComponent} from "./components/auth/login/login.component";
import {PublicDashboardComponent} from "./components/main/home/public/publicdashboard.component";
import {ResetComponent} from "./components/auth/reset/reset.component";
import {PublicLayoutComponent} from "./layouts/public-layout/public-layout.component";
import {ChangePasswordComponent} from "./components/auth/change/change.password.component";
import {TestComponent} from "./components/auth/test/test.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sidoffeng',
    pathMatch: 'full',
  },

  //for admin
  {
    path: 'sidoffeadmin',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }]},

  //for public
  {
    path: 'sidoffepublic',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/public-layout/public-layout.module#PublicLayoutModule'
      }]},


  //login
   {path: 'login', component: LoginComponent},
  {path: 'reset', component: ResetComponent},
  { path: 'change', component: ChangePasswordComponent },
  { path: 'sidoffeng',  component: PublicDashboardComponent },
  // {path: 'register', component: RegisterComponent} ,


  { path: 'test', component: TestComponent },

  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
