// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,


  //LIENS VISUALISATION STATS
  lien_visualisation_pssn : "https://datastudio.google.com/embed/reporting/44e7fb15-0ee4-4e89-818c-b5d6d8d4b88a/page/p_5l7oc955pc",
  lien_visualisation_pe : "https://datastudio.google.com/embed/reporting/44e7fb15-0ee4-4e89-818c-b5d6d8d4b88a/page/p_w427hva5pc",
  lien_visualisation_pffg : "https://datastudio.google.com/embed/reporting/44e7fb15-0ee4-4e89-818c-b5d6d8d4b88a/page/p_me7p2955pc",

  
	  //dev param
   // apiUrl: 'https://localhost:5001/api/',
	
	//apiUrl: 'https://apisidoffe-ng.social.gouv.bj:4443/api/'

						
	  //demo param
       apiUrl: "https://api2019a2024.sidoffe-ng.social.gouv.bj/api/" 
	  
	  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
