import { Component, OnInit, Input  } from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../../shared/services/general/utils.service";
import {ReportService} from "../../../../shared/services/report/report.service";
import {structure} from "../../../../shared/model/structure";
import {ParamService} from "../../../../shared/services/param/param.service";
import {SecuService} from "../../../../shared/services/secu/secu.service";
import {utilisateur} from "../../../../shared/model/utilisateur";
import {LocalStorageService} from "../../../../shared/services/app/localstorage.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as FileSaver from 'file-saver';
import {localisation} from "../../../../shared/model/localisation";

@Component({
  selector: 'app-detailledstats-pffg',
  templateUrl: './detailledstats_pffg.component.html',
  styleUrls: ['./detailledstats_pffg.component.css']
})
export class DetailledStatsPffgComponent implements OnInit {

  constructor(private router: Router, private reportService: ReportService,
              private paramService: ParamService, private secuService: SecuService,
              private localStorage: LocalStorageService, private spinner: NgxSpinnerService,
              private toastr: ToastrService , private utils: UtilsService)
  {
  }

  public detailledStats: any = []; paramSearch: any = { };
  connUser: utilisateur;
  @Input() public is_public: boolean = true ;

  ngOnInit() {
    this.paramSearch = this.localStorage.getSelectedGlobalStatParam();

    //check parameters
    if(this.paramSearch === null){
      let error: string = "Impossible de trouver des critères de recherche. Veuillez rechercher à partir des statistiques globales";
      this.toastr.error(error, "Statistiques détaillées", {positionClass: 'toast-top-center'});
      return;
    }

    this.paramSearch.statistique.Indicateur_Name = decodeURIComponent(this.paramSearch.statistique.Indicateur_Name);

    this.getDetailledPffgStats();
  }//end oninit

  //get list fiches pssn
  getDetailledPffgStats(): void {

    this.paramSearch.indicateur_id = this.paramSearch.statistique.Indicateur_Id;
    this.spinner.show();

    this.reportService.getDetailledPffgStats(this.paramSearch).subscribe(
      (result) => {

        this.spinner.hide();

        if(result === null || result === undefined) {
          this.detailledStats = [];
            let errorMsg: string = "Impossible de récupérer les statistiques détaillées. Veuillez réessayer.";
            this.toastr.error(errorMsg, "Statistiques détaillées", {positionClass: 'toast-top-center'});
              return;
           }
        this.detailledStats = result;

        if(this.detailledStats.length === 0){
          let infoMsg: string = "Aucune statistique trouvée. Veuillez modifier vos critères de recherche puis réessayer.";
          this.toastr.info(infoMsg, "Statistiques détaillées", {positionClass: 'toast-top-center'});
          return;
        }

      },
      (error) => {
        let errorMsg: string = "Une erreur est survenue. Veuillez vérifier votre connexion puis réessayer";
        this.toastr.error(errorMsg, "Statistiques détaillées", {positionClass: 'toast-top-center'});
        return;
      }
    )
  }//end getDetailledPeStats


  //init reset form
  initForm(): void {
    this.paramSearch.startDate = null; this.paramSearch.endDate = null;
  }//end initForm

    //back to global stats
    backToGlobal(): void {
      //this.router.navigate(["//sidoffepublic/stats/global/pffg"]);

		if(this.is_public === true) {
			 this.router.navigate(["/sidoffepublic/stats/global/pffg"]);
		}
		else {
			this.router.navigate(["/sidoffeadmin/stats/pffg"]);
		}
    }//end backToGlobal




  //download
  downloadExcel(): void {

    //this.paramSearch.user = this.connUser.id;

    this.spinner.show();

    this.reportService.printPffgExcelDetailledReport(this.paramSearch).subscribe((result: Blob) => {
        this.spinner.hide();
        if(result === null || result === undefined) {  return; }

        let blob = new Blob([result], {   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'     });
        let filename = 'FICHE_PFFG_DETAILLE.xlsx'; FileSaver.saveAs(blob, filename);
        var url = URL.createObjectURL(blob);  //window.open(url,'_blank');
        return;
      }, (error: any) => {
        console.log(error);
        this.spinner.hide();
        let errorMsg: string = "Une erreur est survenue. Veuillez réessayer";
        this.toastr.error(errorMsg, "Statistiques détaillées", {positionClass: 'toast-top-center'});
        return;
      }

    )
  }//end download excel








}
