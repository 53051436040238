
import {structure} from "./structure";
import {utilisateur} from "./utilisateur";
import {type_donnee_collectee} from "./type_donnee_collectee";
import {FS_PSSN_Detail} from "./FS_PSSN_Detail";
import {FS_PF_FG_Detail} from "./FS_PF_FG_Detail";
import {localisation} from "./localisation";


export class FS_PF_FG {

  public id: number;
  public numero: string;
  public date_saisie: Date;

  public localisation: localisation;
  public localisation_id: number;

  public structure: structure;
  public structure_id: number;

  public statut_fiche_id: number;
  public statut_fiche_name: string;

  public date_debut_periode: Date;
  public date_fin_periode: Date;

  public utilisateur: utilisateur;
  public utilisateur_id: number;

  public login: string;

  public service: string;
  public motif_rejet: string;

  public ou: string;
  public qui: string;
  public quand: string;

  public type_donnee_collectee: type_donnee_collectee;

  //details
  public FS_PF_FG_Detail: FS_PF_FG_Detail[];

  public is_checked: any;


  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {

      // if(this.localisation === undefined || this.localisation === null ){
      //     this.errorMessage = "Veuillez sélectionner l'arrondissement"; return false;
      // }
      // if(this.structure === undefined || this.structure === null ){
      //     this.errorMessage = "Veuillez sélectionner la structure"; return false;
      // }
      // if(this.Service === undefined || this.Service === null || (new String(this.Service)).trim() === ""){
      //   this.errorMessage = "Service manquant"; return false;
      // }

      if(this.date_debut_periode === undefined || this.date_debut_periode === null ){
          this.errorMessage = "Veuillez renseigner la date de début"; return false;
      }
      if(this.date_fin_periode === undefined || this.date_fin_periode === null ){
        this.errorMessage = "Veuillez renseigner la date de fin"; return false;
      }
      //valid dates (anteriorite)
      if(this.date_debut_periode > this.date_fin_periode ){
        this.errorMessage = "La date de fin ne peut être antérieure à la date de début"; return false;
      }

      //mettre heure et minutes à zero chaque fois
      this.date_debut_periode.setHours(2);     this.date_debut_periode.setMinutes(0);
      this.date_fin_periode.setHours(23);     this.date_fin_periode.setMinutes(59);

      //duree entre les dates
      let diff: number = (this.date_fin_periode.getTime() - this.date_debut_periode.getTime());
      let diffDays = (diff / (1000 * 3600 * 24));
      if( (diffDays < 27) || (diffDays > 31) ){
          this.errorMessage = "La période de collecte définie ne correspond pas à un début ou à une fin de mois. Veuillez corriger svp!"; return false;
      }

      //le premier jour doit etre un 1
      if(this.date_debut_periode.getDate() !== 1){
        this.errorMessage = "La date de début de la collecte n'est pas correcte. Veuillez corriger svp!"; return false;
      }

      //type de donnees
      if(this.type_donnee_collectee === undefined || this.type_donnee_collectee === null ){
        this.errorMessage = "Veuillez sélectionner le type de données collecté"; return false;
      }

      //controle sur les items de details
      for(let i: number = 0; i < this.FS_PF_FG_Detail.length; i++){
        let line: FS_PF_FG_Detail = this.FS_PF_FG_Detail[i];

        let isNotEmpty: boolean = line.isNotEmpty();
        let isHandicapValid: boolean = line.isHandicapValid();
        let isTotalValid: boolean = line.isTotalValid();
        let isValid: boolean = line.isValid();

        let allStatus = !isNotEmpty || !isHandicapValid || !isTotalValid  || !isValid ;

        if(allStatus){
          this.errorMessage = line.getErrorMessage() + " // " + line.donnee_collectee.designation ;
          return false;
        }

      }

      return true;
  }//end canSave


  //check for save
  canSaveOnEdit(): boolean {

    if(this.date_debut_periode === undefined || this.date_debut_periode === null ){
      this.errorMessage = "Date de début manquante"; return false;
    }
    if(this.date_fin_periode === undefined || this.date_fin_periode === null ){
      this.errorMessage = "Date de fin manquante"; return false;
    }
    //valid dates (anteriorite)
    if(this.date_debut_periode > this.date_fin_periode ){
      this.errorMessage = "La date de fin ne peut être antérieure à la date de début"; return false;
    }

    //mettre heure et minutes à zero chaque fois
    this.date_debut_periode.setHours(2);     this.date_debut_periode.setMinutes(0);
    this.date_fin_periode.setHours(23);     this.date_fin_periode.setMinutes(59);

    //duree entre les dates
    let diff: number = (this.date_fin_periode.getTime() - this.date_debut_periode.getTime());
    let diffDays = (diff / (1000 * 3600 * 24));
    if( (diffDays < 27) || (diffDays > 31) ){
      this.errorMessage = "La période de collecte définie ne correspond pas à un début ou à une fin de mois. Veuillez corriger svp!"; return false;
    }

    //le premier jour doit etre un 1
    if(this.date_debut_periode.getDate() !== 1){
      this.errorMessage = "La date de début de la collecte n'est pas correcte. Veuillez corriger svp!"; return false;
    }

    //type de donnees
    if(this.type_donnee_collectee === undefined || this.type_donnee_collectee === null ){
      this.errorMessage = "Veuillez sélectionner le type de données collecté"; return false;
    }

    //controle sur les items de details
    for(let i: number = 0; i < this.FS_PF_FG_Detail.length; i++){

      let line: FS_PF_FG_Detail = FS_PF_FG_Detail.recreateFicheFsPffgDetails(this.FS_PF_FG_Detail[i]) ;

      let isNotEmpty: boolean = line.isNotEmpty();
      let isHandicapValid: boolean = line.isHandicapValid();
      let isTotalValid: boolean = line.isTotalValid();
      let isValid: boolean = line.isValid();

      let allStatus = !isNotEmpty || !isHandicapValid || !isTotalValid  || !isValid ;

      if(allStatus){
        this.errorMessage = line.getErrorMessage() + " // " + line.donnee_collectee.designation ;
        return false;
      }

    }

    return true;
  }//end canSaveOnEdit

  //check if can ask for validation
  canAskForValidation(): boolean {

    //type de donnees
    if(this.id === undefined || this.id === null ){
      this.errorMessage = "Veuillez enregistrer au moins une donnée de collecte avant de la faire valider"; return false;
    }

    return true;
  }//end canAskForValidation

}
