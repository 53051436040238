import { Component, Input, Output, EventEmitter, OnInit, OnChanges} from '@angular/core';
import * as moment from 'moment';
import {ConstantsService} from "../../../shared/services/general/constants.service";


@Component({
  selector: 'legende-synthese',
  templateUrl: './legende-synthese.component.html',
  styleUrls: ['./legende-synthese.component.css']
})

export class LegendeSyntheseComponent implements  OnInit{


  public constructor(
  ) {

  }

  ngOnInit(): void{
  }




}
