
import * as EmailValidator from 'email-validator';


export class commentaire {

  public id: number;
  public email: string;
  public identity: string;
  public message: string;

  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {
    if(this.identity === undefined || this.identity === null || this.identity.trim() === ""){
      this.errorMessage = "Veuillez renseigner votre identité"; return false;
    }

      if(this.email === undefined || this.email === null || this.email.trim() === ""){
          this.errorMessage = "Veuillez renseigner votre adresse email"; return false;
      }


    //adresse email valide
    if(!EmailValidator.validate(this.email)){
      this.errorMessage = "Votre adresse mail est invalide"; return false;
    }

    if(this.message === undefined || this.message === null || this.message.trim() === ""){
      this.errorMessage = "Veuillez indiquer votre message"; return false;
    }

      return true;
  }//end canSave


}
