
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {AppService} from "../app/app.service";
import {domaine} from "../../model/domaine";
import {thematique} from "../../model/thematique";
import {type_donnee_collectee} from "../../model/type_donnee_collectee";
import {donnee_collectee} from "../../model/donnee_collectee";
import {type_structure} from "../../model/type_structure";
import { glossaire } from '../../model/glossaire';
import {structure} from "../../model/structure";
import {groupe} from "../../model/groupe";
import {utilisateur} from "../../model/utilisateur";
import {droit} from "../../model/droit";
import {groupe_droit} from "../../model/groupe_droit";
import {localisation} from "../../model/localisation";
import {niveau_hierarchique} from "../../model/niveau_hierarchique";
import {parametre} from "../../model/parametre";
import {commentaire} from "../../model/commentaire";


@Injectable()
export class SecuService {

  
  constructor(private http: HttpClient, private appService: AppService) {
  }


  ///////////////////////////////////////niveaux hierarchiques area /////////////////////////////////

  // get niveaux_hierarchiques list
  getNiveauxHierarchiques() : Observable<niveau_hierarchique[]> {
    return this.http.get<niveau_hierarchique[]>(`${this.appService.getBaseUrl()}secu/niveauxhierarchiques`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<niveau_hierarchique[]>(``))
      )
  }//fin getNiveauxHierarchiques



  ///////////////////////////////////////options typesstructures area /////////////////////////////////

  // get typestsructure list
  getTypesStructure() : Observable<type_structure[]> {
    return this.http.get<type_structure[]>(`${this.appService.getBaseUrl()}secu/typesstructures`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<type_structure[]>(``))
      )
  }//fin getTypesStructure

  //create typestructure
  createTypeStructure(item: type_structure) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/createtypestructure`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<type_structure>(``))
      )
  }//fin createTypeStructure

  //update typestructure
  updateTypeStructure(item: type_structure) : Observable<any> {
    return this.http.put(`${this.appService.getBaseUrl()}secu/updatetypestructure`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<type_structure>(``))
      )
  }//fin updateTypeStructure

  //delete typestructure
  deleteTypeStructure(item: type_structure) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/deletetypestructure`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<type_structure>(``))
      )
  }//fin deleteTypeStructure



  ///////////////////////////////////////options structures area /////////////////////////////////

  // get structure list
  getStructures() : Observable<structure[]> {
    return this.http.get<structure[]>(`${this.appService.getBaseUrl()}secu/structures`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<structure[]>(``))
      )
  }//fin getStructures

  // search structure list
  searchStructures(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/searchstructures`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<structure>(``))
      )
  }//fin searchStructures

  // get structure list by commune
  getStructuresByCommune(item: localisation) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/structuresbycommune`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<structure>(``))
      )
  }//fin getStructuresByCommune

  //create structure
  createStructure(item: structure) : Observable<any> {
    item.localisation_id = item.localisation.id;
    item.type_structure_id = item.type_structure.id;
    item.niveau_hierarchique_id = item.niveau_hierarchique.id;

    return this.http.post(`${this.appService.getBaseUrl()}secu/createstructure`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<structure>(``))
      )
  }//fin createStructure

  //update structure
  updateStructure(item: structure) : Observable<any> {
    item.localisation_id = item.localisation.id;
    item.type_structure_id = item.type_structure.id;
    item.niveau_hierarchique_id = item.niveau_hierarchique.id;

    return this.http.put(`${this.appService.getBaseUrl()}secu/updatestructure`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<structure>(``))
      )
  }//fin updateStructure

  //delete structure
  deleteStructure(item: structure) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/deletestructure`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<structure>(``))
      )
  }//fin deleteStructure


  ///////////////////////////////////////options groupes area /////////////////////////////////

  // get groupe list
  getGroupes() : Observable<groupe[]> {
    return this.http.get<groupe[]>(`${this.appService.getBaseUrl()}secu/groupes`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<groupe[]>(``))
      )
  }//fin getGroupes

  //create groupe
  createGroupe(item: groupe) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/creategroupe`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<groupe>(``))
      )
  }//fin createGroupe

  //update groupe
  updateGroupe(item: groupe) : Observable<any> {
    return this.http.put(`${this.appService.getBaseUrl()}secu/updategroupe`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<groupe>(``))
      )
  }//fin updateGroupe

  //delete groupe
  deleteGroupe(item: groupe) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/deletegroupe`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<groupe>(``))
      )
  }//fin deleteGroupe


  ///////////////////////////////////////options users area /////////////////////////////////

  // get user list
  getUsers() : Observable<utilisateur[]> {
    return this.http.get<utilisateur[]>(`${this.appService.getBaseUrl()}secu/utilisateurs`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur[]>(``))
      )
  }//fin getUsers

  // search user list
  searchUsers(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}secu/searchutilisateurs`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin searchUsers

  //create user
  createUser(item: utilisateur) : Observable<any> {
    item.groupe_id = item.groupe.id;
    item.structure_id = item.structure.id;

    return this.http.post(`${this.appService.getBaseUrl()}secu/createutilisateur`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin createUser

  //update user
  updateUser(item: utilisateur) : Observable<any> {
    item.groupe_id = item.groupe.id;
    item.structure_id = item.structure.id;

    return this.http.put(`${this.appService.getBaseUrl()}secu/updateutilisateur`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``))
      )
  }//fin updateUser

  //delete user
  deleteUser(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/deleteutilisateur`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``))
      )
  }//fin deleteUser

  ///////////////////////////////////////options droits listing /////////////////////////////////

  // get droits list
  getDroits() : Observable<droit[]> {
    return this.http.get<droit[]>(`${this.appService.getBaseUrl()}secu/droits`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<droit[]>(``))
      )
  }//fin getDroits

  // get droits by groupe
  getDroitsByGroupe(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}secu/droitsbygroupe`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<groupe_droit>(``)
        )
      )
  }//fin getDroitsByGroupe

  //enregistre les droits du groupe
  saveUserRights(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}secu/createdroitsgroupe`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<groupe_droit>(``)
        )
      )
  }//fin saveUserRights




  ///////////////////////////////////////options typesstructures area /////////////////////////////////

  // get parametre list
  getParametres() : Observable<parametre[]> {
    return this.http.get<parametre[]>(`${this.appService.getBaseUrl()}params/parametres`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<parametre[]>(``))
      )
  }//fin getParametres

  //update parametre
  updateParametre(item: parametre) : Observable<any> {
    return this.http.put(`${this.appService.getBaseUrl()}params/updateparametre`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<parametre>(``))
      )
  }//fin updateParametre


  //create comment
  createComment(item: commentaire) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}secu/createcomment`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<parametre>(``))
      )
  }//fin createComment





}

