import { glossaire } from './../../model/glossaire';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {AppService} from "../app/app.service";
import {domaine} from "../../model/domaine";
import {thematique} from "../../model/thematique";
import {type_donnee_collectee} from "../../model/type_donnee_collectee";
import {donnee_collectee} from "../../model/donnee_collectee";
import {type_structure} from "../../model/type_structure";
import {structure} from "../../model/structure";
import {utilisateur} from "../../model/utilisateur";
import {localisation} from "../../model/localisation";


@Injectable()
export class ParamService {

  constructor(private http: HttpClient, private appService: AppService) {
  }

  //get departements list
  getDepartements() : Observable<localisation[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}params/departements`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
  )
  }//fin getDepartements

  // search departements list
  searchDepartements(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}params/searchdepartements`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        )
      )
  }//fin searchDepartements

  //get communes par departements list
  getCommunesByDepartement(item: localisation) : Observable<localisation[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}params/communes/${item.id}`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getCommunesByDepartement

  //get arrond par communes list
  getArrondByCommunes(item: localisation) : Observable<localisation[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}params/arrondissements/${item.id}`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getArrondByCommunes

  //get communes list
  getCommunes() : Observable<localisation[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}params/communesall`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getCommunes

  // search communes list
  searchCommunes(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}params/searchcommunes`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        )
      )
  }//fin searchCommunes

  // get arrondissements list
  getArrondissements() : Observable<localisation[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}params/arrondissementsall`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
      )
  }//fin getArrondissements

  // search arrondissements list
  searchArrondissements(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}params/searcharrondissements`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        )
      )
  }//fin searchArrondissements


  ///////////////////fiche des synthese//////////////////
  //get domaines list
  getDomaines() : Observable<domaine[]> {
    return this.http.get<domaine[]>(`${this.appService.getBaseUrl()}params/domaines`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<domaine[]>(``))
      )
  }//fin getDomaines

  //get thematique par domaine list
  getThematiquesByDomaine(itemId: number) : Observable<thematique[]> {
    return this.http.get<thematique[]>(`${this.appService.getBaseUrl()}params/thematiques/${itemId}`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<thematique[]>(``))
      )
  }//fin getThematiquesByDomaine


  //get typedonnee par thematique list
  getTypeDonneeCollecteeByThematique(item: thematique) : Observable<type_donnee_collectee[]> {
    return this.http.get<type_donnee_collectee[]>(`${this.appService.getBaseUrl()}params/typesdonneescollectees/${item.id}`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<type_donnee_collectee[]>(``))
      )
  }//fin getTypeDonneeCollecteeByThematique

  //get donneecollectee par typedonneecollectee list
  getDonneeCollecteeByTypeDonneeCollecte(item: type_donnee_collectee) : Observable<donnee_collectee[]> {
    return this.http.get<donnee_collectee[]>(`${this.appService.getBaseUrl()}params/donneescollectees/${item.id}`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<donnee_collectee[]>(``))
      )
  }//fin getTypeDonneeCollecteeByThematique
 
  //get statuts list
  getStatuts() : Observable<localisation[]> {
    return this.http.get<localisation[]>(`${this.appService.getBaseUrl()}params/statuts`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation[]>(``))
  )
  }//fin getStatuts


   //create glossaire
   createGlossaire(item: glossaire) : Observable<glossaire[]> {
    return this.http.post<glossaire[]>(`${this.appService.getBaseUrl()}params/createGlossaire`, item)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<glossaire[]>(``))
      )
  }//fin create glossaire

   // search glossaire list
   SearchLinesInGlossaire(item: any) : Observable<any> {

  return this.http.post(`${this.appService.getBaseUrl()}params/SearchLinesInGlossaire`,
    item, this.appService.httpOptions)
    .pipe(
      //tap(_ => console.log(``)),
      catchError(this.appService.handleError<glossaire>(``)
      )
    )
}//fin searchInGlossaire

   //get glossaire list
   getListGlossaire() : Observable<glossaire[]> {
    return this.http.get<glossaire[]>(`${this.appService.getBaseUrl()}params/getListGlossaire`)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<glossaire[]>(``))
      )
  }//fin glossaire list

   //update glossaire 
   updateGlossaire(item: glossaire) : Observable<glossaire[]> {
    return this.http.put<glossaire[]>(`${this.appService.getBaseUrl()}params/updateGlossaire`, item)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<glossaire[]>(``))
      )
  }//fin update glossaire

   //delete glossaire
   deleteGlossaire(item : glossaire) : Observable<glossaire[]> {
    return this.http.post<glossaire[]>(`${this.appService.getBaseUrl()}params/deleteGlossaire`, item)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<glossaire[]>(``))
      )
  }//fin delete glossaire






}

