import { Injectable } from '@angular/core';
import {Observable} from "rxjs/index";
import {catchError} from "rxjs/operators";
import {utilisateur} from "../../model/utilisateur";
import {AppService} from "../app/app.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private appService: AppService) { }


  //login
  login(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}auth/login`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin login


  //reset
  reset(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}auth/reset`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin reset

  //reset
  resetOnFirstLogin(item: utilisateur) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}auth/resetonfirstlogin`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<utilisateur>(``)
        )
      )
  }//fin resetOnFirstLogin



}
