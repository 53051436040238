
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {AppService} from "../app/app.service";



@Injectable()
export class ReportService {

  constructor(private http: HttpClient, private appService: AppService) {
  }

  //get pssn stats list
  getPssnStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pssn`,
        param, this.appService.httpOptions)
        .pipe(
          catchError(this.appService.handleError<any>(``)
          )
        )
  }//fin getPssnStats

  // print pssn pdf report
  printPssnPdfReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printpdfglobalpssn`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),
        //catchError(this.appService.handleError<any>(``)
        //)
      )
  }//fin printPssnPdfReport

  // print pssn excel report
  printPssnExcelReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printexcelglobalpssn`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),
        //catchError(this.appService.handleError<any>(``)
        //)
      )
  }//fin printPssnExcelReport


  // print pssn excel detailled report
  printPssnExcelDetailledReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printexceldetailledpssn`,
      item, this.appService.pdfOptions )
      .pipe(
      )
  }//fin printPssnExcelDetailledReport



  //get pe stats list
  getPeStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pe`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getPeStats

  // print pe pdf report
  printPePdfReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printpdfglobalpe`,
      item, this.appService.pdfOptions )
      .pipe(
      )
  }//fin printPePdfReport

  // print pe excel report
  printPeExcelReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printexcelglobalpe`,
      item, this.appService.pdfOptions )
      .pipe(
      )
  }//fin printPeExcelReport

  // print pffg excel detailled report
  printPeExcelDetailledReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printexceldetailledpe`,
      item, this.appService.pdfOptions )
      .pipe(
      )
  }//fin printPeExcelDetailledReport


  //get pssn stats list
  getPffgStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pffg`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getPffgStats

  // print pffg pdf report
  printPffgPdfReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printpdfglobalpffg`,
      item, this.appService.pdfOptions )
      .pipe(
      )
  }//fin printPffgPdfReport


  // print pffg excel report
  printPffgExcelReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printexcelglobalpffg`,
      item, this.appService.pdfOptions )
      .pipe(
      )
  }//fin printPffgExcelReport


  // print pffg excel detailled report
  printPffgExcelDetailledReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printexceldetailledpffg`,
      item, this.appService.pdfOptions )
      .pipe(
      )
  }//fin priprintPffgExcelDetailledReportntPffgExcelReport


  /////////////////////////////////////// area preview and others /////////////////////////////////


  // preview pssn file
  previewPssnFiche(item: any) : Observable<any> {
    this.appService.pdfOptions.responseType = 'blob';
    return this.http.post(`${this.appService.getBaseUrl()}reports/printpssnfiche`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),  //catchError(this.appService.handleError<any>(``)  //)
      )
  }//fin previewPssnFiche

  // preview pe file
  previewPeFiche(item: any) : Observable<any> {
    this.appService.pdfOptions.responseType = 'blob';
    return this.http.post(`${this.appService.getBaseUrl()}reports/printpefiche`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),  //catchError(this.appService.handleError<any>(``)  //)
      )
  }//fin previewPeFiche

  // preview pffg file
  previewPffgFiche(item: any) : Observable<any> {
    this.appService.pdfOptions.responseType = 'blob';
    return this.http.post(`${this.appService.getBaseUrl()}reports/printpffgfiche`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),  //catchError(this.appService.handleError<any>(``)  //)
      )
  }//fin previewPffgFiche

  //get dashboard stats list
  getDashboardStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/dashboardstats`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getDashboardStats

  //get departements stats list
  getDepartementsStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/departementsstats`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getDepartementsStats


  /////////////////////////////////////// area detailled stats /////////////////////////////////


  //get pe detailled stats list
  getDetailledPeStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pedetailled`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getDetailledPeStats

  //get pssn detailled stats list
  getDetailledPssnStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pssndetailled`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getDetailledPssnStats

  //get pffg detailled stats list
  getDetailledPffgStats(param: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}stats/pffgdetailled`,
      param, this.appService.httpOptions)
      .pipe(
        catchError(this.appService.handleError<any>(``)
        )
      )
  }//fin getDetailledPffgStats

  // print pe report
  printDetailledPeReport(item: any) : Observable<any> {

    this.appService.pdfOptions.responseType = 'blob';

    return this.http.post(`${this.appService.getBaseUrl()}reports/printglobalpe`,
      item, this.appService.pdfOptions )
      .pipe(
        //tap(_ => console.log(``)),
        //catchError(this.appService.handleError<any>(``)
        //)
      )
  }//fin printDetailledPeReport


}

