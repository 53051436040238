import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SecuService} from "../../../shared/services/secu/secu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {LibraryService} from "../../../shared/services/app/library.service";
import {ParamService} from "../../../shared/services/param/param.service";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../shared/services/general/utils.service";
import {ConfirmationService} from "primeng/api";
import {utilisateur} from "../../../shared/model/utilisateur";
import {AuthService} from "../../../shared/services/auth/auth.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change.password.component.html',
  styleUrls: ['./change.password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  newUser : utilisateur = new utilisateur();

  constructor(private router: Router, private paramService: ParamService, private libraryService: LibraryService,
              private spinner: NgxSpinnerService, private authService: AuthService,
              private toastr: ToastrService , private utils: UtilsService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
  }



  //save request
  saveRequestChangePassword(): void {
    if(!this.newUser.canReset()){
      this.toastr.error(this.newUser.getErrorMessage(), "Connexion", {positionClass: 'toast-top-center'});
      return;
    }
    this.spinner.show();
    this.authService.reset(this.newUser).subscribe((result) => {
      this.spinner.hide();

      if(result.status === false){
        let error: string = result.message;
        this.toastr.error(error, "Réinitialisation", {positionClass: 'toast-top-center'});
        return;
      }else{
        this.newUser.adresse_mail = "";
        let error: string = result.message;
        this.toastr.success(error, "Réinitialisation", {positionClass: 'toast-top-center'});
        return;
      }

      //this.router.navigate(["/admin/home"]);
    })
  }//end saveRequestChangePassword



}
