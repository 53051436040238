import {donnee_collectee} from "./donnee_collectee";
import {ConstantsService} from "../services/general/constants.service";


export class FS_PSSN_Detail {

  public id: number;
  public fs_pssn_id: number;
  public donnees_collectees_id: number;
  public donnee_collectee: donnee_collectee;

  public Sexe_H: number;
  public Sexe_F: number;


  public Type_Secours_Enfance_Malheureuse: number;
  public Type_Secours_Indigent: number;
  public Type_Secours_Sinistre: number;
  public Type_Secours_Immediat: number;

  public Type_Filet_Social_Transfert_Monetaire_Conditionnel: number;
  public Type_Filet_Social_Transfert_Monetaire_Inconditionnel: number;

  public Type_Appui_Formation: number;
  public Type_Appui_Installation: number;

  public Type_Appui_Nutritionnel: number;
  public Type_Appui_Materiel: number;
  public Type_Appui_Ludique: number;
  public Type_Appui_Medical: number;

  public TA_0_17: number;
  public TA_18_59: number;

  // public TA_18_34: number;
  // public TA_35_59: number;

  public TA_SUP_60: number;

  //public TA_55_69: number;
  //public TA_SUP_70: number;
  
  public HMI: number;
  public HMS: number;
  public HA: number;
  public HV: number;
  public HM: number;
  public AFH: number;

  public Total: number;

  public Handicap: number;

  public ou: string;
  public qui: string;
  public quand: string;


  //validation fields
  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  public getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check if is not empty
  public isNotEmpty(): boolean {
    let statusNotEmpty: boolean = false;

    statusNotEmpty =
      (this.Sexe_H !== undefined && this.Sexe_H !== null) && (this.Sexe_F !== undefined && this.Sexe_F !== null)
      && (
        //condition on group 1 of ages
        ( (this.TA_0_17 !== undefined && this.TA_0_17 !== null) && (this.TA_18_59 !== undefined && this.TA_18_59 !== null)
        //&& (this.TA_18_34 !== undefined && this.TA_18_34 !== null) && (this.TA_35_59 !== undefined && this.TA_35_59 !== null) && (this.TA_55_69 !== undefined && this.TA_55_69 !== null) 
          && (this.TA_SUP_60 !== undefined && this.TA_SUP_60 !== null)
         // && (this.TA_SUP_70 !== undefined && this.TA_SUP_70 !== null)
         )
     );

      //handicap
	  if( this.donnee_collectee.designation !== ConstantsService.LABEL_MONTANT_SECOURS_OCTROYE && this.donnee_collectee.designation !== ConstantsService.LABEL_MONTANT_SECOURS_SOLLICITE
		&& this.donnee_collectee.designation !== ConstantsService.DESIGNATION_INDICATEUR_PSSN_3  && this.donnee_collectee.designation !== ConstantsService.DESIGNATION_INDICATEUR_PSSN_4
			&& this.donnee_collectee.designation !== ConstantsService.DESIGNATION_INDICATEUR_PSSN_5 && this.donnee_collectee.designation !== ConstantsService.DESIGNATION_INDICATEUR_PSSN_20 ){
       statusNotEmpty = statusNotEmpty && 
			  ( (this.HMI !== undefined && this.HMI !== null) && (this.HMS !== undefined && this.HMS !== null) && (this.HA !== undefined && this.HA !== null) 
			&&   (this.HV !== undefined && this.HV !== null) && (this.HM !== undefined && this.HM !== null) && (this.AFH !== undefined && this.AFH !== null)  );
	  }  

    //some other cases
    //type secours
    if(this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_1 || this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_2 ){
      statusNotEmpty = statusNotEmpty &&
        //condition on types secours
        ( (this.Type_Secours_Enfance_Malheureuse !== undefined && this.Type_Secours_Enfance_Malheureuse !== null) && (this.Type_Secours_Indigent !== undefined && this.Type_Secours_Indigent !== null)  && (this.Type_Secours_Sinistre !== undefined && this.Type_Secours_Sinistre !== null)   && (this.Type_Secours_Immediat !== undefined && this.Type_Secours_Immediat !== null)  )
    }

    //filet social
    if(this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_3 || this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_4  || this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_5 ){
      statusNotEmpty = statusNotEmpty &&
        //condition on filet social
        ( (this.Type_Filet_Social_Transfert_Monetaire_Conditionnel !== undefined && this.Type_Filet_Social_Transfert_Monetaire_Conditionnel !== null) && (this.Type_Filet_Social_Transfert_Monetaire_Inconditionnel !== undefined && this.Type_Filet_Social_Transfert_Monetaire_Inconditionnel !== null)  )
    }

    //cas sociaux
    if(this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_6 || this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_7){
      statusNotEmpty = statusNotEmpty &&
        //condition on cas sociaux
        ( (this.Type_Appui_Formation !== undefined && this.Type_Appui_Formation !== null) && (this.Type_Appui_Installation !== undefined && this.Type_Appui_Installation !== null) )
    }

    //appui general
    if(this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_8 || this.donnee_collectee.designation === ConstantsService.DESIGNATION_INDICATEUR_PSSN_9){
      statusNotEmpty = statusNotEmpty &&
        //condition on appui general
        ( (this.Type_Appui_Nutritionnel !== undefined && this.Type_Appui_Nutritionnel !== null) && (this.Type_Appui_Materiel !== undefined && this.Type_Appui_Materiel !== null) && (this.Type_Appui_Ludique !== undefined && this.Type_Appui_Ludique !== null)  && (this.Type_Appui_Medical!== undefined && this.Type_Appui_Medical !== null)  )
    }

    if(!statusNotEmpty) { this.errorMessage = "Veuillez renseigner tous les champs";  }  else {  return statusNotEmpty; }

    //return statusNotEmpty;
  } //end isNotEmpty

  //check if handicap is valid
  public isHandicapValid(): boolean {
    if(!this.isNotEmpty()) return false;
    let statusHandicapValid: boolean = false;

    statusHandicapValid =
      ( (this.HMI + this.HMS + this.HA + this.HV +this.HM + this.AFH ) <= (this.Sexe_F + this.Sexe_H)  );
    if(!statusHandicapValid) { this.errorMessage = "Veuillez renseigner une valeur de handicap valide"; } else {   return statusHandicapValid; }   // pour l'indicateur " + this.donnee_collectee.designation ;

    //return statusHandicapValid;
  }//end isHandicapValid

  //check if total sexe and total ages are valid
  public isTotalValid(): boolean {

    //check not emptyness
    if(!this.isNotEmpty()) return false;

    //set default values because of totals
    let lineSexe_H: number = this.Sexe_H; if(this.Sexe_H === undefined || this.Sexe_H === null) lineSexe_H = 0;
    let lineSexe_F: number = this.Sexe_F; if(this.Sexe_F === undefined || this.Sexe_F === null) lineSexe_F = 0;

    let lineTA_0_17: number = this.TA_0_17; if(this.TA_0_17 === undefined || this.TA_0_17 === null) lineTA_0_17 = 0;
    let lineTA_18_59: number = this.TA_18_59; if(this.TA_18_59 === undefined || this.TA_18_59 === null) lineTA_18_59 = 0;
    let lineTA_SUP_60: number = this.TA_SUP_60; if(this.TA_SUP_60 === undefined || this.TA_SUP_60 === null) lineTA_SUP_60 = 0;

    // let lineTA_18_34: number = this.TA_18_34; if(this.TA_18_34 === undefined || this.TA_18_34 === null) lineTA_18_34 = 0;
    // let lineTA_35_59: number = this.TA_35_59; if(this.TA_35_59 === undefined || this.TA_35_59 === null) lineTA_35_59 = 0;
    // let lineTA_55_69: number = this.TA_55_69; if(this.TA_55_69 === undefined || this.TA_55_69 === null) lineTA_55_69 = 0;
    // let lineTA_SUP_70: number = this.TA_SUP_70; if(this.TA_SUP_70 === undefined || this.TA_SUP_70 === null) lineTA_SUP_70 = 0;

    //other levels
    //type secours
    let lineType_Secours_Enfance_Malheureuse: number = this.Type_Secours_Enfance_Malheureuse; if(this.Type_Secours_Enfance_Malheureuse === undefined || this.Type_Secours_Enfance_Malheureuse === null) lineType_Secours_Enfance_Malheureuse = 0;
    let lineType_Secours_Indigent: number = this.Type_Secours_Indigent; if(this.Type_Secours_Indigent === undefined || this.Type_Secours_Indigent === null) lineType_Secours_Indigent = 0;
    let lineType_Secours_Sinistre: number = this.Type_Secours_Sinistre; if(this.Type_Secours_Sinistre === undefined || this.Type_Secours_Sinistre === null) lineType_Secours_Sinistre = 0;
    let lineType_Secours_Immediat: number = this.Type_Secours_Immediat; if(this.Type_Secours_Immediat === undefined || this.Type_Secours_Immediat === null) lineType_Secours_Immediat = 0;


    //filet social
    let lineType_Filet_Social_Transfert_Monetaire_Conditionnel: number = this.Type_Filet_Social_Transfert_Monetaire_Conditionnel; if(this.Type_Filet_Social_Transfert_Monetaire_Conditionnel === undefined || this.Type_Filet_Social_Transfert_Monetaire_Conditionnel === null) lineType_Filet_Social_Transfert_Monetaire_Conditionnel = 0;
    let lineType_Filet_Social_Transfert_Monetaire_Inconditionnel: number = this.Type_Filet_Social_Transfert_Monetaire_Inconditionnel; if(this.Type_Filet_Social_Transfert_Monetaire_Inconditionnel === undefined || this.Type_Filet_Social_Transfert_Monetaire_Inconditionnel === null) lineType_Filet_Social_Transfert_Monetaire_Inconditionnel = 0;

    //appui formation installation
    let lineType_Appui_Formation: number = this.Type_Appui_Formation; if(this.Type_Appui_Formation === undefined || this.Type_Appui_Formation === null) lineType_Appui_Formation = 0;
    let lineType_Appui_Installation: number = this.Type_Appui_Installation; if(this.Type_Appui_Installation === undefined || this.Type_Appui_Installation === null) lineType_Appui_Installation = 0;


    //appui nutritionnel et autres
    let lineType_Appui_Nutritionnel: number = this.Type_Appui_Nutritionnel; if(this.Type_Appui_Nutritionnel === undefined || this.Type_Appui_Nutritionnel === null) lineType_Appui_Nutritionnel = 0;
    let lineType_Appui_Materiel: number = this.Type_Appui_Materiel; if(this.Type_Appui_Materiel === undefined || this.Type_Appui_Materiel === null) lineType_Appui_Materiel = 0;
    let lineType_Appui_Ludique: number = this.Type_Appui_Ludique; if(this.Type_Appui_Ludique === undefined || this.Type_Appui_Ludique === null) lineType_Appui_Ludique = 0;
    let lineType_Appui_Medical: number = this.Type_Appui_Medical; if(this.Type_Appui_Medical === undefined || this.Type_Appui_Medical === null) lineType_Appui_Medical = 0;

    //check now
    let statusTotalValid: boolean = false;

    if( (this.donnee_collectee.designation.trim() !== ConstantsService.DESIGNATION_INDICATEUR_PSSN_12.trim()) && (this.donnee_collectee.designation.trim() !== ConstantsService.DESIGNATION_INDICATEUR_PSSN_13.trim()) ){
      statusTotalValid =
        (  (lineSexe_H + lineSexe_F) === (lineTA_0_17 + lineTA_18_59 + 
          //lineTA_18_34 + lineTA_35_59 + lineTA_55_69 +  + lineTA_SUP_70)
          lineTA_SUP_60 ) );
    }else{
      statusTotalValid = true;
    }

    if(!statusTotalValid)  {
      this.errorMessage = "Les totaux des niveaux de désagrégation Age et Sexe ne sont pas conformes"; return statusTotalValid;
    }

    //continue checks for other level desagregations
    //type secours
    if(this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_1.trim() || this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_2.trim() ){
      statusTotalValid =
        //condition on types secours
        ( (lineType_Secours_Enfance_Malheureuse + lineType_Secours_Indigent + lineType_Secours_Sinistre + lineType_Secours_Immediat) <= (this.Total) );   //lineSexe_H + lineSexe_F
      if(!statusTotalValid)  { this.errorMessage = "Les totaux des niveaux de désagrégation Type Secours et Sexe ne sont pas conformes"; return statusTotalValid; }
  }

    //filet social
    if(this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_3.trim() || this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_4.trim()  || this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_5.trim() ){
      statusTotalValid =
        //condition on filet social
        ( (lineType_Filet_Social_Transfert_Monetaire_Conditionnel + lineType_Filet_Social_Transfert_Monetaire_Inconditionnel) <=  (this.Total) );   //lineSexe_H + lineSexe_F

      if(!statusTotalValid)  { this.errorMessage = "Les totaux des niveaux de désagrégation Filet Social et Sexe ne sont pas conformes"; return statusTotalValid; }

    }

    //cas sociaux
    if(this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_6.trim() || this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_7.trim()){
      statusTotalValid =
        //condition on cas sociaux
        ( (lineType_Appui_Formation + lineType_Appui_Installation) <=  (this.Total) );   //lineSexe_H + lineSexe_F
      if(!statusTotalValid)  { this.errorMessage = "Les totaux des niveaux de désagrégation Appui et Sexe ne sont pas conformes"; return statusTotalValid; }
    }

    //appui general
    if(this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_8.trim() || this.donnee_collectee.designation.trim() === ConstantsService.DESIGNATION_INDICATEUR_PSSN_9.trim()){
      statusTotalValid =
        //condition on appui general
        (  (lineType_Appui_Nutritionnel + lineType_Appui_Materiel + lineType_Appui_Ludique + lineType_Appui_Medical) <= (this.Total) );   //lineSexe_H + lineSexe_F
      if(!statusTotalValid)  { this.errorMessage = "Les totaux des niveaux de désagrégation Appui et Sexe ne sont pas conformes"; return statusTotalValid; }
    }

    return statusTotalValid;
  }//end isTotalValid



  //check if is valid
  public isValid(): boolean {
    //check not emptyness
    if(!this.isNotEmpty()) return false;

    let statusValid: boolean = false;

    statusValid =
      (this.Sexe_H >= 0) && (this.Sexe_F >= 0)
      && (
        //condition on group 1 of ages
        ( (this.TA_0_17 >= 0 ) 
        //&& (this.TA_18_34  >= 0)
       && (this.TA_18_59  >= 0)
        //&& (this.TA_35_59  >= 0)  && (this.TA_55_69  >= 0)  
        && (this.TA_SUP_60  >= 0) 
        //&& (this.TA_SUP_70  >= 0) 
         )
      )

      //other conditionns left because no longer needed

      //handicap
      && (this.Handicap  >= 0);

    if(!statusValid)  { this.errorMessage = "Veuillez vérifier les valeurs saisies"; } else {  return statusValid; } // + this.donnee_collectee.designation ;


  }//end isValid



  //recreate fiche fspssn details
  public static recreateFicheFsPssnDetails(item: any): FS_PSSN_Detail {
    let newItem: FS_PSSN_Detail = new FS_PSSN_Detail();

    newItem.id = item.id;
    newItem.fs_pssn_id = item.fs_pssn_id;
    newItem.donnees_collectees_id = item.donnees_collectees_id;
    newItem.donnee_collectee = item.donnee_collectee;
    newItem.Sexe_H = item.Sexe_H;
    newItem.Sexe_F = item.Sexe_F;

    newItem.Type_Secours_Enfance_Malheureuse = item.Type_Secours_Enfance_Malheureuse;
    newItem.Type_Secours_Indigent = item.Type_Secours_Indigent;
    newItem.Type_Secours_Sinistre = item.Type_Secours_Sinistre;
    newItem.Type_Secours_Immediat = item.Type_Secours_Immediat;

    newItem.Type_Filet_Social_Transfert_Monetaire_Conditionnel = item.Type_Filet_Social_Transfert_Monetaire_Conditionnel;
    newItem.Type_Filet_Social_Transfert_Monetaire_Inconditionnel = item.Type_Filet_Social_Transfert_Monetaire_Inconditionnel;

    newItem.Type_Appui_Formation = item.Type_Appui_Formation;
    newItem.Type_Appui_Installation = item.Type_Appui_Installation;

    newItem.Type_Appui_Nutritionnel = item.Type_Appui_Nutritionnel;
    newItem.Type_Appui_Materiel = item.Type_Appui_Materiel;
    newItem.Type_Appui_Ludique = item.Type_Appui_Ludique;
    newItem.Type_Appui_Medical = item.Type_Appui_Medical;

    newItem.TA_0_17 = item.TA_0_17;
    newItem.TA_18_59 = item.TA_18_59;
    newItem.TA_SUP_60 = item.TA_SUP_60;

    // newItem.TA_18_34 = item.TA_18_34;
    // newItem.TA_35_59 = item.TA_35_59;
    // newItem.TA_55_69 = item.TA_55_69;
    // newItem.TA_SUP_70 = item.TA_SUP_70;

    newItem.Total = item.Total;
    newItem.Handicap = item.Handicap;
	
	newItem.HMI = item.HMI;
	newItem.HMS = item.HMS;
	newItem.HA = item.HA;
	newItem.HV = item.HV;
	newItem.HM = item.HM;
	newItem.AFH = item.AFH;

    newItem.ou = item.ou;
    newItem.quand = item.quand;
    newItem.qui = item.qui;

    return newItem;
  }//end recreateFicheFsPssnDetails


}
