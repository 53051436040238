    export const environment = {
      production: true,
      
	  
      //LIENS VISUALISATION STATS
      lien_visualisation_pssn : "https://datastudio.google.com/embed/reporting/44e7fb15-0ee4-4e89-818c-b5d6d8d4b88a/page/p_5l7oc955pc",
      lien_visualisation_pe : "https://datastudio.google.com/embed/reporting/44e7fb15-0ee4-4e89-818c-b5d6d8d4b88a/page/p_w427hva5pc",
      lien_visualisation_pffg : "https://datastudio.google.com/embed/reporting/44e7fb15-0ee4-4e89-818c-b5d6d8d4b88a/page/p_me7p2955pc",

      
	  //production params https 
	 apiUrl: 'https://apisidoffe-ng.social.gouv.bj/api/'

	//'https://apisidoffe-ng.social.gouv.bj:4443/api/'

    };

    // ng build --prod --aot --vc --cc --dop --buildOptimizer
    // ng build --prod --aot --build-optimizer

    //  ng  build --aot --prod --build-optimizer --output-hashing none
