import {groupe} from "./groupe";
import {structure} from "./structure";
import * as EmailValidator from 'email-validator';


export class utilisateur {

    public id: number;
    public login: string;
    public nom: string;
    public prenoms: string;
    public mot_passe: string;
    public mot_passe_confirmation: string;
    public adresse_mail: string;
    public contact: string;
    public photo: string;
    public est_premiere_connexion: boolean;

    public structure_id: number;
    public structure: structure;

    public groupe_id: number;
    public groupe: groupe;

    public ou: string;
    public qui: string;
    public quand: any;

    public autorisations : [];


    //for password change
  public old_mot_passe: string;
  public new_mot_passe: string;
  public new_mot_passe_confirmation: string;

    private errorMessage: string;

    constructor(){
        this.errorMessage = "";
    }

    //get error message
    getErrorMessage(): string {
        return this.errorMessage;
    }//end getErrorMessage


    //check for save
    canSave(): boolean {

        if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
            this.errorMessage = "Nom de l'utilisateur manquant"; return false;
        }
        if(this.prenoms === undefined || this.prenoms === null || this.prenoms.trim() === ""){
            this.errorMessage = "Prénom de l'utilisateur manquant"; return false;
        }
        if(this.adresse_mail === undefined || this.adresse_mail === null || this.adresse_mail.trim() === ""){
            this.errorMessage = "Adresse mail manquante"; return false;
        }
        //adresse email valide
        if(!EmailValidator.validate(this.adresse_mail)){
          this.errorMessage = "Adresse mail invalide"; return false;
        }

        if(this.contact === undefined || this.contact === null || this.contact.trim() === ""){
            this.errorMessage = "Contact téléphonique manquant"; return false;
        }

        if(this.groupe === undefined || this.groupe === null || this.groupe.id === undefined){
          this.errorMessage = "groupe de l'utilisateur manquant"; return false;
        }
        if(this.structure === undefined || this.structure === null || this.structure.id === undefined){
          this.errorMessage = "structure de l'utilisateur manquante"; return false;
        }

        if(this.login === undefined || this.login === null || this.login.trim() === ""){
          this.errorMessage = "Login manquant"; return false;
        }

        //mot de passe
        if(this.mot_passe === undefined || this.mot_passe === null || this.mot_passe.trim() === ""){
          this.errorMessage = "Mot de passe manquant"; return false;
        }
        if(this.mot_passe.length < 5){
          this.errorMessage = "Le Mot de passe doit contenir au moins 5 caractères"; return false;
        }

        //mot de passe de confirmation
        if(this.mot_passe_confirmation === undefined || this.mot_passe_confirmation === null || this.mot_passe_confirmation.trim() === ""){
          this.errorMessage = "Mot de passe de confirmation manquant"; return false;
        }
        if(this.mot_passe !== this.mot_passe_confirmation){
          this.errorMessage = "Le mot de passe et sa confirmation doivent être identiques"; return false;
        }
        if(this.mot_passe_confirmation.length < 5){
          this.errorMessage = "Le Mot de passe de confirmation doit contenir au moins 5 caractères"; return false;
        }

        return true;
    }//end canSave



    //check for login
    canLogin(): boolean {
      if(this.login === undefined || this.login === null || this.login.trim() === ""){
        this.errorMessage = "Login manquant"; return false;
      }
      if(this.mot_passe === undefined || this.mot_passe === null || this.mot_passe.trim() === ""){
        this.errorMessage = "Mot de passe manquant"; return false;
      }

        return true;
    }//end canLogin

  //check for reset
  canReset(): boolean {
    if(this.adresse_mail === undefined || this.adresse_mail === null || this.adresse_mail.trim() === ""){
      this.errorMessage = "Adresse email manquante"; return false;
    }
    //adresse email valide
    if(!EmailValidator.validate(this.adresse_mail)){
      this.errorMessage = "Adresse mail invalide"; return false;
    }

    return true;
  }//end canReset

  //check for reset on first login
  canResetOnFirstLogin(): boolean {
    if(this.old_mot_passe === undefined || this.old_mot_passe === null || this.old_mot_passe.trim() === ""){
      this.errorMessage = "Mot de passe actuel manquant"; return false;
    }

    if(this.new_mot_passe === undefined || this.new_mot_passe === null || this.new_mot_passe.trim() === ""){
      this.errorMessage = "Nouveau Mot de passe manquant"; return false;
    }

    if(this.new_mot_passe_confirmation === undefined || this.new_mot_passe_confirmation === null || this.new_mot_passe_confirmation.trim() === ""){
      this.errorMessage = "Confirmation de mot de passe manquante"; return false;
    }
    if(this.new_mot_passe !== this.new_mot_passe_confirmation){
      this.errorMessage = "Le mot de passe et sa confirmation doivent être identiques"; return false;
    }

    if(this.new_mot_passe.length < 5){
      this.errorMessage = "Le Mot de passe de confirmation doit contenir au moins 5 caractères"; return false;
    }

    return true;
  }//end canResetOnFirstLogin

}
