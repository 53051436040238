import { Component, OnInit, Input  } from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UtilsService} from "../../../../shared/services/general/utils.service";
import {ReportService} from "../../../../shared/services/report/report.service";
import {structure} from "../../../../shared/model/structure";
import {ParamService} from "../../../../shared/services/param/param.service";
import {SecuService} from "../../../../shared/services/secu/secu.service";
import {utilisateur} from "../../../../shared/model/utilisateur";
import {LocalStorageService} from "../../../../shared/services/app/localstorage.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as FileSaver from 'file-saver';
import {localisation} from "../../../../shared/model/localisation";
import { ActivatedRoute } from '@angular/router';
import {LibraryService} from "../../../../shared/services/app/library.service";
import { ConstantsService } from 'src/app/shared/services/general/constants.service';

@Component({
  selector: 'app-publicglobalstats-pe',
  templateUrl: './publicglobalstats_pe.component.html',
  styleUrls: ['./publicglobalstats_pe.component.css']
})
export class PublicGlobalStatsPeComponent implements OnInit {

  constructor(private router: Router, private reportService: ReportService, private libraryService: LibraryService,
              private paramService: ParamService, private secuService: SecuService,
              private localStorage: LocalStorageService, private spinner: NgxSpinnerService,
              private toastr: ToastrService , private utils: UtilsService, private route: ActivatedRoute)
  {
  }

  public pssnStats: any[] = [];
  departements: localisation[];
  communes: localisation[]; structures: structure[];
  selectedDepartement: localisation;  selectedCommune: localisation;
  connUser: utilisateur;
  codeIndic: string;   private sub: any;
  paramSearch: any = { startDate: new Date(), endDate: new Date(), codeIndic: "", departement: "",
   commune: "", thematique: "", sous_thematique: "", statistique: ""    };
  dataFromStorage: any;

  ID_PE: number = ConstantsService.REFERENCE_DOMAINE_PE;
  thematiques: any[] = [];
  sous_thematiques: any[] = [];
  savedSearchParam: any = {};

  @Input() public is_public: boolean = true ;


  ngOnInit() {
    this.dataFromStorage = this.localStorage.getSelectedStat();

    //get search param from storage
    this.savedSearchParam = this.localStorage.getSelectedGlobalStatParam();

    if(this.savedSearchParam !== undefined && this.savedSearchParam !== null){
      this.paramSearch.startDate = this.savedSearchParam.startDate;
      this.paramSearch.endDate = this.savedSearchParam.endDate;

    }else { //first time on this page
      this.codeIndic = "";
      this.paramSearch.codeIndic = this.codeIndic;
      this.paramSearch.thematique = null;
      this.paramSearch.sous_thematique = null;
    }

    //this.getStatsPeFiches();
    this.getListDepartements();
    this.getThematiquesByDomaine();
  }//end oninit

  ngOnDestroy() {
  }

    //get list des thematiques par domaine
    getThematiquesByDomaine(): void {
      this.paramService.getThematiquesByDomaine(this.ID_PE).subscribe((result) => {
        this.thematiques = result;

        //preset thematique if previously selected
        if(this.savedSearchParam !== undefined && this.savedSearchParam !== null && this.savedSearchParam.thematique !== undefined){
          let thematiqueList: any[] = this.thematiques.filter(x => x.id === this.savedSearchParam.thematique.id);
          if(thematiqueList.length !== 0){
            this.paramSearch.thematique = thematiqueList[0];
            this.getTypeDonneeByThematique();
          }
        }//end preset

      })
    }//end getThematiquesByDomaine

      //get list des types de donnees par thematique
  getTypeDonneeByThematique(): void {
    if(this.paramSearch.thematique.id === undefined) return;
    this.paramService.getTypeDonneeCollecteeByThematique(this.paramSearch.thematique).subscribe((result) => {
      this.sous_thematiques = result;

      //preset sous thematique if previously selected
      if(this.savedSearchParam !== undefined && this.savedSearchParam !== null && this.savedSearchParam.sous_thematique !== undefined){
        let sousThematiqueList: any[] = this.sous_thematiques.filter(x => x.id === this.savedSearchParam.sous_thematique.id);
        if(sousThematiqueList.length !== 0){
          this.paramSearch.sous_thematique = sousThematiqueList[0];
        }
      }//end preset

    })
  }//end getTypeDonneeByThematique

  //get list fiches pssn
  getStatsPeFiches(): void {
    if(this.paramSearch.sous_thematique !==  undefined && this.paramSearch.sous_thematique !==  null){
      this.paramSearch.codeIndic = this.paramSearch.sous_thematique.code;
    }else{
        let errorMsg: string = "Veuillez sélectionner une thématique puis une sous-thématique avant de continuer.";
        this.toastr.error(errorMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
        return;
    }

    //check on departement and commune
    if(this.paramSearch.departement !== null && this.paramSearch.departement !== undefined  && this.paramSearch.departement.id === undefined){
      this.paramSearch.departement = null;
    }
    if(this.paramSearch.commune !== null && this.paramSearch.commune !== undefined  && this.paramSearch.commune.id === undefined){
      this.paramSearch.commune = null;
    }

    this.spinner.show();

    this.reportService.getPeStats(this.paramSearch).subscribe((result) => {
        this.spinner.hide();

      if(result === null || result === undefined) {
         this.pssnStats = [];
         let errorMsg: string = "Impossible de récupérer les statistiques globales. Veuillez svp vérifier votre connexion internet.";
         this.toastr.error(errorMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
         return;
      }
      this.pssnStats = result;

      if(this.pssnStats.length === 0){
          let infoMsg: string = "Aucune statistique trouvée. Veuillez svp vérifier vos critères de recherche !";
          this.toastr.info(infoMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
          return;
      }

    },
    (error: any) => {
      this.spinner.hide();
      let errorMsg: string = "Impossible de récupérer les statistiques globales. Veuillez réessayer svp.";
      this.toastr.error(errorMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
      return;
    })
  }//end getStatsPeFiches

  //get list departements
  getListDepartements(): void {
    this.paramService.getDepartements().subscribe((result) => {
      this.departements = result;

      //preset departement if previously selected
      if(this.savedSearchParam !== undefined && this.savedSearchParam !== null && this.savedSearchParam.departement !== undefined
        && this.savedSearchParam.departement !== null){
        let departementList: any[] = this.departements.filter(x => x.id === this.savedSearchParam.departement.id);
        if(departementList.length !== 0){
          this.paramSearch.departement = departementList[0];
          this.getListCommunesByDepartement();
        }
      }//end preset

    })
  }//end getListDepartements

  //get list communes par departements
  getListCommunesByDepartement(): void {
    this.paramService.getCommunesByDepartement(this.paramSearch.departement).subscribe((result) => {
      this.communes = result;

      //preset commune if previously selected
      if(this.savedSearchParam !== undefined && this.savedSearchParam !== null && this.savedSearchParam.commune !== undefined
        && this.savedSearchParam.commune !== null){
        let communeList: any[] = this.communes.filter(x => x.id === this.savedSearchParam.commune.id);
        if(communeList.length !== 0){
          this.paramSearch.commune = communeList[0];
        }
      }//end preset

    })
  }//end getListCommunesByDepartement

    //back to home
    backToHome(): void {
      this.router.navigate(["//"]);
    }//end backToHome

  //date de debut
  getSelectedDateStart(event: Date){
    if(event !== undefined) event.setHours(this.libraryService.getBrowserOffset());
    if(event !== undefined) event.setMinutes(1);
    this.paramSearch.startDate = event;
  }

  //date de fin
  getSelectedDateEnd(event: Date){
    if(event !== undefined) event.setHours(this.libraryService.getBrowserOffset());
    if(event !== undefined) event.setMinutes(1);
    this.paramSearch.endDate = event;
  }

  //init reset form
  initForm(): void {
    this.paramSearch.departement = null;  this.paramSearch.commune = null;
    this.paramSearch.structure = null;
    this.paramSearch.startDate = null; this.paramSearch.endDate = null;
    this.paramSearch.thematique = null;
    this.paramSearch.sous_thematique = null;
  }//end initForm


  //download pdf
  downloadPdf(): void {

    if(this.paramSearch.sous_thematique !==  undefined && this.paramSearch.sous_thematique !==  null){
      this.paramSearch.codeIndic = this.paramSearch.sous_thematique.code;
    }else{
      let errorMsg: string = "Veuillez sélectionner une thématique puis une sous-thématique avant de continuer.";
      this.toastr.error(errorMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
      return;
  }

    //check on departement and commune
    if(this.paramSearch.departement !== null && this.paramSearch.departement !== undefined  && this.paramSearch.departement.id === undefined){
      this.paramSearch.departement = null;
    }
    if(this.paramSearch.commune !== null && this.paramSearch.commune !== undefined  && this.paramSearch.commune.id === undefined){
      this.paramSearch.commune = null;
    }

    this.spinner.show();

    this.reportService.printPePdfReport(this.paramSearch).subscribe((result: Blob) => {

      this.spinner.hide();
      if(result === null || result === undefined) {  return; }

      //let fileBlob = result.Blob();
      let blob = new Blob([result], {   type: 'application/pdf'     });
      let filename = 'FICHE_PE_GLOBAL.pdf'; FileSaver.saveAs(blob, filename);
      var url = URL.createObjectURL(blob);  window.open(url,'_blank');

    },
      (error: any) => {
        this.spinner.hide();
        console.log("error");
        let errorMsg: string = "Une erreur est survenue. Veuillez réessayer";
        this.toastr.error(errorMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
        return;
    })

  }//end downloadPdf pdf

  //download
  downloadExcel(): void {

    if(this.paramSearch.sous_thematique !==  undefined && this.paramSearch.sous_thematique !==  null){
      this.paramSearch.codeIndic = this.paramSearch.sous_thematique.code;
    }else{
      let errorMsg: string = "Veuillez sélectionner une thématique puis une sous-thématique avant de continuer.";
      this.toastr.error(errorMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
      return;
    }

    //check on departement and commune
    if(this.paramSearch.departement !== null && this.paramSearch.departement !== undefined  && this.paramSearch.departement.id === undefined){
      this.paramSearch.departement = null;
    }
    if(this.paramSearch.commune !== null && this.paramSearch.commune !== undefined  && this.paramSearch.commune.id === undefined){
      this.paramSearch.commune = null;
    }

    this.spinner.show();

    this.reportService.printPeExcelReport(this.paramSearch).subscribe((result: Blob) => {
        this.spinner.hide();
        if(result === null || result === undefined) {  return; }

        let blob = new Blob([result], {   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'     });
        let filename = 'FICHE_PE_GLOBAL.xlsx'; FileSaver.saveAs(blob, filename);
        var url = URL.createObjectURL(blob);  //window.open(url,'_blank');
        return;
      }, (error: any) => {
        console.log(error);
        this.spinner.hide();
        let errorMsg: string = "Une erreur est survenue. Veuillez réessayer";
        this.toastr.error(errorMsg, "Statistiques globales", {positionClass: 'toast-top-center'});
        return;
      }

    )
  }//end download excel


  //get linne class
  getLineClass(item: any){
    if(item.unite === "%"){
      return "bolded";
    }else{
      return "";
    }
  }//end getLineClass

  //show detailled stats
  showDetailledStats(item: any){

    //save params
    item.Indicateur_Name = encodeURIComponent(item.Indicateur_Name);

    this.paramSearch.statistique = item;

    this.localStorage.saveSelectedGlobalStatParam(this.paramSearch);

    //route
	if(this.is_public === true) {
		 this.router.navigate(["/sidoffepublic/stats/details/pe"]);
	}
	else {
		this.router.navigate(["/sidoffeadmin/stats/details/pe"]);
	}


  }//end showDetailledStats




}
