


export class parametre {

  public id: number;
  public designation: string;
  public valeur: string;

  private errorMessage: string;

  constructor(){
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSave(): boolean {
      if(this.designation === undefined || this.designation === null || this.designation.trim() === ""){
          this.errorMessage = "Désignation manquante"; return false;
      }

    if(this.valeur === undefined || this.valeur === null || this.valeur.trim() === ""){
      this.errorMessage = "Valeur manquante"; return false;
    }

      return true;
  }//end canSave


}
