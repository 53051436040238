
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {AppService} from "../app/app.service";
import {domaine} from "../../model/domaine";
import {thematique} from "../../model/thematique";
import {type_donnee_collectee} from "../../model/type_donnee_collectee";
import {donnee_collectee} from "../../model/donnee_collectee";
import {type_structure} from "../../model/type_structure";
import {structure} from "../../model/structure";
import {groupe} from "../../model/groupe";
import {FS_PSSN} from "../../model/FS_PSSN";
import {utilisateur} from "../../model/utilisateur";
import {FS_PE} from "../../model/FS_PE";
import {FS_PF_FG} from "../../model/FS_PF_FG";
import {localisation} from "../../model/localisation";


@Injectable()
export class OperationService {

  constructor(private http: HttpClient, private appService: AppService) {
  }

  ///////////////////////////////////////options fiches de synthese fspssn/////////////////////////////////

  //create fiche fspssn
  createFicheFsPssn(item: FS_PSSN) : Observable<any> {
    // item.localisation_id = item.localisation.id;
    // item.structure_id = item.structure.id;

    return this.http.post(`${this.appService.getBaseUrl()}operation/createfichefspssn`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin createFicheFsPssn

    //create fiche fspssn
    updateFicheFsPssn(item: FS_PSSN) : Observable<any> {
      // item.localisation_id = item.localisation.id;
      // item.structure_id = item.structure.id;

      return this.http.post(`${this.appService.getBaseUrl()}operation/updatefichefspssn`,
        item, this.appService.httpOptions )
        .pipe(  //tap(_ => console.log(``)),
          catchError(this.appService.handleError<any>(``))
        )
    }//fin updateFicheFsPssn

   // get fichefspssn list
   getFichesFsPssn(item: any) : Observable<any> {

     return this.http.post(`${this.appService.getBaseUrl()}operation/fichesfspssn`, item,
       this.appService.httpOptions )
       .pipe(
         //tap(_ => console.log(``)),
         catchError(this.appService.handleError<FS_PSSN[]>(``))
       )
  }//fin getFichesFsPssn

  // search fiches fspssn list
  searchFichesFsPssn(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/searchfichesfspssn`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PSSN[]>(``)
        )
      )
  }//fin searchFichesFsPssn

  // search fiches fspssn list
  consultFichesFsPssn(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/consultfichesfspssn`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PSSN[]>(``)
        )
      )
  }//fin consultFichesFsPssn


  //delete fiche
  deleteFicheFsPssn(item: FS_PSSN) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/deletefichefspssn`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PSSN>(``))
      )
  }//fin deleteFicheFsPssn

  // get fiche details list by typedonnee
  getFicheDetailsPssnByTypeDonnee(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/donneescollecteespssnedit`,
      item, this.appService.httpOptions)
      .pipe( //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        ) )
  }//fin getFicheDetailsPssnByTypeDonnee


  //ask fiche fspssn validation
  askFicheFsPssnEditValidation(item: FS_PSSN) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}operation/askfichefspssneditvalidation`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin askFicheFsPssnValidation

  //ask fiche fspssn validation
  validateFicheFsPssn(item: FS_PSSN) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/validatefichefspssn`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin validateFicheFsPssn


  ///////////////////////////////////////options structures area fspssn/////////////////////////////////


  ///////////////////////////////////////options fiches de synthese fspe/////////////////////////////////

  //create fiche fspe
  createFicheFsPe(item: FS_PE) : Observable<any> {
    // item.localisation_id = item.localisation.id;
    // item.structure_id = item.structure.id;

    return this.http.post(`${this.appService.getBaseUrl()}operation/createfichefspe`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin createFicheFsPe

  //create fiche fspssn
  updateFicheFsPe(item: FS_PE) : Observable<any> {
    // item.localisation_id = item.localisation.id;
    // item.structure_id = item.structure.id;

    return this.http.post(`${this.appService.getBaseUrl()}operation/updatefichefspe`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin updateFicheFsPe

  // get fichefspssn list
  getFichesFsPe(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}operation/fichesfspe`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PE[]>(``))
      )
  }//fin getFichesFsPe

  // search fiches fspe list
  searchFichesFsPe(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/searchfichesfspe`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PE[]>(``))
      )
  }//fin searchFichesFsPe

  // consult fiches fspe list
  consultFichesFsPe(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/consultfichesfspe`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PE[]>(``))
      )
  }//fin consultFichesFsPe


  //delete fiche pe
  deleteFicheFsPe(item: FS_PE) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/deletefichefspe`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PE>(``))
      )
  }//fin deleteFicheFsPe

  // get fiche details list by typedonnee
  getFicheDetailsPeByTypeDonnee(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/donneescollecteespeedit`,
      item, this.appService.httpOptions)
      .pipe( //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        ) )
  }//fin getFicheDetailsPeByTypeDonnee


  //ask fiche fspssn validation
  askFicheFsPeEditValidation(item: FS_PE) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}operation/askfichefspeeditvalidation`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin askFicheFsPeValidation

  //ask fiche fspe validation
  validateFicheFsPe(item: FS_PE) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/validatefichefspe`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin validateFicheFsPssn


  ///////////////////////////////////////options structures area fspe/////////////////////////////////




  ///////////////////////////////////////options fiches de synthese fspffg/////////////////////////////////

  //create fiche fspffg
  createFicheFsPfFg(item: FS_PF_FG) : Observable<any> {
    // item.localisation_id = item.localisation.id;
    // item.structure_id = item.structure.id;

    return this.http.post(`${this.appService.getBaseUrl()}operation/createfichefspffg`,
      item, this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin createFicheFsPfFg

  //create fiche fspffg
  updateFicheFsPfFg(item: FS_PF_FG) : Observable<any> {
    // item.localisation_id = item.localisation.id;
    // item.structure_id = item.structure.id;

    return this.http.post(`${this.appService.getBaseUrl()}operation/updatefichefspffg`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin updateFicheFsPfFg

  // get fspffg list
  getFichesFsPfFg(item: any) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}operation/fichesfspffg`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PF_FG[]>(``))
      )
  }//fin getFichesFsPfFg

  // search fiches fspffg list
  searchFichesFsPfFg(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/searchfichesfspffg`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PF_FG[]>(``))
      )
  }//fin searchFichesFsPfFg

  // consult fiches fspffg list
  consultFichesFsPfFg(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/consultfichesfspffg`,
      item, this.appService.httpOptions)
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PF_FG[]>(``))
      )
  }//fin consultFichesFsPfFg


  //delete fiche fspffg
  deleteFicheFsPfFg(item: FS_PF_FG) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/deletefichefspffg`, item,
      this.appService.httpOptions )
      .pipe(
        //tap(_ => console.log(``)),
        catchError(this.appService.handleError<FS_PF_FG>(``))
      )
  }//fin deleteFicheFsPfFg

  // get fspffg details list by typedonnee
  getFicheDetailsPfFgByTypeDonnee(item: any) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/donneescollecteespffgedit`,
      item, this.appService.httpOptions)
      .pipe( //tap(_ => console.log(``)),
        catchError(this.appService.handleError<localisation>(``)
        ) )
  }//fin getFicheDetailsPfFgByTypeDonnee


  //ask fiche fspffg validation
  askFicheFsPfFgEditValidation(item: FS_PF_FG) : Observable<any> {

    return this.http.post(`${this.appService.getBaseUrl()}operation/askfichefspffgeditvalidation`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin askFicheFsPfFgValidation

  //ask fiche fspffg validation
  validateFicheFsPfFg(item: FS_PF_FG) : Observable<any> {
    return this.http.post(`${this.appService.getBaseUrl()}operation/validatefichefspffg`,
      item, this.appService.httpOptions )
      .pipe(  //tap(_ => console.log(``)),
        catchError(this.appService.handleError<any>(``))
      )
  }//fin validateFicheFsPfFg


  ///////////////////////////////////////options structures area fspffg /////////////////////////////////





}

